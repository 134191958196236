import React from 'react';
import  PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Slide, TextField } from '@mui/material';

const  Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
})

const ConfirmDialog =  (props) => (
  <Dialog
    open    = { props.show }
    onClose = { props.onClose }
    TransitionComponent={ Transition }
  >
    <DialogTitle > { props.title } </DialogTitle>
    <DialogContent>
      <DialogContentText> { props.message } </DialogContentText>
      <TextField
          autoFocus
          fullWidth
          type     =  { props.inputType }
          label    =  { props.inputLabel }
          onChange =  { (e) => props.onChangeText(e.target.value) }
      />
    </DialogContent>
    <DialogActions>
      <Button onClick = { props.onClose  } color="primary">
        { props.cancelText }
      </Button>
      <Button onClick = { props.onSubmit } color="primary">
        { props.confirmText }
      </Button>
    </DialogActions>
  </Dialog>)

ConfirmDialog.propTypes = {
    show:       PropTypes.bool,
    title:      PropTypes.string,
    message:    PropTypes.string,
    inputLabel: PropTypes.string,
    cancelText: PropTypes.string,
    confirmText:PropTypes.string,
    onClose:    PropTypes.func,
    onSubmit:   PropTypes.func,
}
ConfirmDialog.defaultProps = {
    show:       false,
    title:      'No Title',
    inputLabel: 'Enter Value',
    message:    'No message....',
    cancelText: 'Cancel',
    confirmText:'Submit',
    inputType:  'text',
    onClose:    () => console.log('Not set...'),
    onSubmit:   () => console.log('Not set...'),
    onChangeText:() => console.log('Not set...')
}

export default ConfirmDialog;