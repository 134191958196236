import CATEGORY_CONST from './category-constants';
import action from '../action-generator';
import Utility from '../../lib/utility';

export const unmountCategories = () => action(CATEGORY_CONST.UNMOUNT_CATEGORIES, null); 
export const setSnackMsg = (msg = null) => action(CATEGORY_CONST.SET_SNACK_MSG, msg);


/***************************************************************************************************
 *              Async actions
 ***************************************************************************************************/

export const createCategory    = (url, body, cb) => asyncAction(url, body, cb, 'createCategory');
export const fetchCategories   = (url, body, cb) => asyncAction(url, body, cb, 'fetchCategories');
export const deleteCategory    = (url, body, cb) => asyncAction(url, body, cb, 'deleteCategory');

/***************************************************************************************************
 *              Helper method for async actions
 ***************************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'createCategory': {
            startAction     = CATEGORY_CONST.CREATE_CATEGORY_START;
            successAction   = CATEGORY_CONST.CREATE_CATEGORY_SUCCESS;
            failAction      = CATEGORY_CONST.CREATE_CATEGORY_FAIL;
            break;
        }
        case 'fetchCategories': {
            startAction     = CATEGORY_CONST.FETCH_CATEGORIES_START;
            successAction   = CATEGORY_CONST.FETCH_CATEGORIES_SUCCESS;
            failAction      = CATEGORY_CONST.FETCH_CATEGORIES_FAIL;
            break;
        }
        case 'deleteCategory': {
            startAction     = CATEGORY_CONST.DELETE_CATEGORY_START;
            successAction   = CATEGORY_CONST.DELETE_CATEGORY_SUCCESS;
            failAction      = CATEGORY_CONST.DELETE_CATEGORY_FAIL;
            break;
        }
        default: {
            startAction     = CATEGORY_CONST.FETCH_CATEGORIES_START;
            successAction   = CATEGORY_CONST.FETCH_CATEGORIES_SUCCESS;
            failAction      = CATEGORY_CONST.FETCH_CATEGORIES_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        }) 
    }
}