import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Paper, TextField, RadioGroup, Radio, FormControlLabel, FormLabel } from '@mui/material';

import { DialogImage } from '../components';

import { ErrorText } from '../../../../components/messages';
import { updateStaff, setSnackMsgs, fetchStaffList } from '../../../../redux/staff/staff-actions';
import { PrimaryButton, DialogDismissButton } from '../../../../components/buttons';
import Utility from '../../../../lib/utility';
import { endPoints } from '../../../../lib/values';

const inputProps = {
    classes: {
        root:           "creator-small-box-input-wrapper", 
        notchedOutline: "notched-outline" 
    }
}

const inputPropsDisable = {
    classes: {
        root:           "creator-small-box-input-wrapper input-bg-disabled", 
        notchedOutline: "notched-outline" 
    }
}

class UpdateStaff extends Component {

    constructor(props){
        super(props);
        this.state = {
            user: props.user,
        }
    }

    onChangeText = (e) => {
        this.setState({user: { ...this.state.user, [e.target.name]: e.target.value } });
    }

    onUpdateUser = () => {
        const { user }  = this.state;
        const url       = `${endPoints.employees.updateEmployee}/${user._id}`;
        const reqBody   = {
            fullname:        user.fullname,
            email:           user.email,
            mobile:          user.mobile,
            role:            user.role,
        }
        
        this.props.updateStaff(url, Utility.httpRequest("POST", reqBody), (err, success) => {
            if(err === null){
                this.props.refreshList()
            }
        })
    }


    render(){
        const { user } = this.state;
        const { staffReducer } = this.props;
    return (
        <Paper classes = {{root: 'creator-small-box'}} elevation={0}>
            <DialogImage 
                
                imageSrc    = { this.props.user.image }
            />

            <TextField 
                fullWidth 
                variant     = "outlined"
                InputProps  = {inputProps} 
                label       = "Fullname" 
                name        = "fullname"
                value       = { user.fullname }
                onChange    = { this.onChangeText }
            />
            <TextField 
                fullWidth
                variant     = "outlined"
                InputProps  = {inputProps} 
                label       = "Email" 
                name        = "email"
                value       = { user.email }
                onChange    = { this.onChangeText }
            /> 
            <TextField 
                fullWidth
                variant     = "outlined"
                type        = { "number" }
                InputProps  = {inputProps} 
                label       = "Mobile" 
                name        = "mobile"
                value       = { user.mobile }
                onChange    = { this.onChangeText }
            /> 

            <div style={{marginLeft: 5}}>
                <FormLabel> Role </FormLabel>
                <RadioGroup
                    value       = { user.role.toString() }
                    style       = {{display: 'flex', flexDirection: 'row', marginBottom: 20}}
                    onChange    = {(e, v) => this.setState({ user: {...this.state.user, 'role': v }}) }
                >
                    <FormControlLabel
                        value= { '1' }
                        control={<Radio color="primary" />}
                        label="Admin"
                    />
                    <FormControlLabel
                        value={ '2' }
                        control={<Radio color="primary" />}
                        label="Manager"
                    />
                    <FormControlLabel
                        value={ '3' }
                        control={<Radio color="primary" />}
                        label="Publisher"
                    />
                    <FormControlLabel
                        value={ '4' }
                        control={<Radio color="primary" />}
                        label="Editor"
                    />
                </RadioGroup>
            </div>

            <PrimaryButton  
                buttonFullWidth
                buttonText      = "Update"
                buttonVariant   = "contained"
                buttonSize      = "large"
                inProgress      = { staffReducer.updatingStaff }
                buttonDisabled  = { staffReducer.updatingStaff }
                onClick         = { this.onUpdateUser }
            />
            { staffReducer.snackMsg  && <ErrorText  text = { staffReducer.snackMsg } onClose = {() => this.props.setSnackMsgs(null) }  /> } 

            <DialogDismissButton 
                onClick = { this.props.onClickDismissBtn } 
            />
        
        </Paper>
    )
}
}

function mapStateToProps(state){
    return {
        staffReducer: state.staffReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsgs:   (msg)           => dispatch(setSnackMsgs(msg)),
        updateStaff:    (url, body, cb) => dispatch(updateStaff(url, body, cb)),
        fetchStaffList: (url, body, cb) => dispatch(fetchStaffList(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStaff);