import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Container } from '../../components/container';
import { PrimaryButton } from '../../components/buttons';
import { logout, setSessionData } from '../../redux/auth/auth-actions';

class ErrorPage extends Component {

    state = {
        navigateNow: false,
    }

    componentDidMount(){
       this.props.authReducer.sessionData.msg && this.props.logout()
    }

    renderError = () => {
        const { authReducer } = this.props;
        return (
            <div style={{textAlign: 'center'}}>
                <h2>{ authReducer.sessionData.title || "Error"}</h2>
                <h4 style={{color: '#222'}}> { authReducer.sessionData.msg || 'Something went wrong please login again!' } </h4>
                <PrimaryButton  
                    buttonText  = "Login Again"
                    onClick     = {() => { this.props.setSessionData({}); this.setState({ navigateNow: true }) }}
                />
            </div> 
        )
    }

    render(){
        return this.props.authReducer.sessionData.msg === null 
        ? <Navigate to = "/auth" />
        : 
        <Container authContainer>
            { this.state.navigateNow && <Navigate to = '/auth' /> }
            { this.props.authReducer.sessionData.msg ? this.renderError() : null }
        </Container>
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer,
    }
}
function mapDispatchToProps(dispatch){
    return  {
        logout:             ()      => dispatch(logout()),
        setSessionData:    (msg)   => dispatch(setSessionData(msg))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);