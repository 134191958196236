import React , { Component } from 'react';
import { connect } from 'react-redux';
import { Paper, TextField, Typography } from '@mui/material';

import { PrimaryButton, DialogDismissButton } from '../../../../components/buttons';
import { createCategory, fetchCategories } from '../../../../redux/category/category-actions';
import Utility  from '../../../../lib/utility';
import { endPoints, reqMethods } from '../../../../lib/values';

class CreateCategory extends Component {

    state = {
        inputs: undefined,
    }

    onChangeText = (e) => {
        this.setState({inputs: { ...this.state.inputs, [e.target.name]:  e.target.value }}, () => console.log(this.state.inputs))
    }

    onClickSave = (e) => {
        this.props.onSave();
        this.props.createCategory(endPoints.categories.createCategory, Utility.httpRequest(reqMethods.post, this.state.inputs), (err, success) => {
            if(success){
                this.props.fetchCategories(endPoints.categories.allCategories, Utility.httpRequest(reqMethods.get), (err, success) => {
                    // do something after fetch categories
                })
            }  
        })
    }

    render(){
        return (
            <Paper classes = {{root: 'creator-small-box'}} elevation={0}>
                <Typography variant="h5" component="h3" style = {{ marginBottom: 20 }}>
                    New / Edit category
                </Typography>

                <TextField 
                    fullWidth 
                    variant     = "outlined"
                    InputProps  = {{classes: {root: 'creator-small-box-input-wrapper', notchedOutline: "notched-outline" }}} 
                    label       = "Title English" 
                    name        = "title_en"
                    onChange    = { this.onChangeText }
                />

                <TextField 
                    fullWidth 
                    variant     = "outlined"
                    InputProps  = {{classes: {root: 'creator-small-box-input-wrapper', notchedOutline: "notched-outline" }}} 
                    label       = "Title Urdu" 
                    name        = "title_ur"
                    onChange    = { this.onChangeText }
                /> 

                <PrimaryButton  
                    buttonFullWidth
                    buttonText      = "Save"
                    onClick         = { this.onClickSave }
                />

                <DialogDismissButton 
                    onClick = { this.props.onClickDismiss } 
                />

            </Paper>
        )
    }
}

function mapStateToProps(state){
    return {
        categoryReducer: state.categoryReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCategory:    (url, body, cb)    => dispatch(createCategory(url, body, cb)),
        fetchCategories:   (url, body, cb)    => dispatch(fetchCategories(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);