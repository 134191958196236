import React from 'react';
import { Paper, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';

import { setSnackMsgs, addStaff } from '../../../../redux/staff/staff-actions';
import { PrimaryButton, DialogDismissButton } from '../../../../components/buttons';
import { ErrorText } from '../../../../components/messages';
import Utility from '../../../../lib/utility';
import { endPoints, reqMethods } from '../../../../lib/values';


const inputProps = {
    classes: {
        root:           "creator-small-box-input-wrapper", 
        notchedOutline: "notched-outline" 
    }
}

class AddStaff extends React.Component {

    state = {
        fullname:   undefined,
        email:      undefined,
        mobile:     undefined,
    }

    onSave = () => {
        let jsonBody = {
            fullname:   this.state.fullname,
            email:      this.state.email,
            mobile:     this.state.mobile,
        }
        this.props.addStaff(endPoints.employees.addEmployee, Utility.httpRequest(reqMethods.post, jsonBody), (err, success) => {
            if(err === null){
                return this.props.onAddSuccess();
            }
        })
    }

    render() { 
        const { staffReducer } = this.props;

        return (
            <Paper classes = {{root: 'creator-small-box'}} elevation={0}>
                <Typography 
                    variant     = "h5" 
                    component   = "h3" 
                    style       = {{ marginBottom: 20 }}>
                    New Employee
                </Typography>

                <TextField 
                    fullWidth
                    variant     = "outlined"
                    label       = "Fullname" 
                    InputProps  = { inputProps } 
                    onChange    = { e => this.setState({fullname: e.target.value}) }
                />

                <TextField 
                    fullWidth 
                    variant     = "outlined"
                    label       = "Email" 
                    InputProps  = { inputProps } 
                    onChange    = { e => this.setState({email: e.target.value}) }
                /> 

                <TextField 
                    fullWidth 
                    type        = "number"
                    variant     = "outlined"
                    label       = "Mobile" 
                    InputProps  = { inputProps } 
                    onChange    = { e => this.setState({mobile: e.target.value}) }
                /> 

                <PrimaryButton  
                    buttonFullWidth
                    buttonText      = "Save"
                    buttonSize      = "large"
                    buttonVariant   = "contained"
                    inProgress      = { this.props.staffReducer.addingStaff }
                    buttonDisabled  = { this.props.staffReducer.addingStaff }
                    onClick         = { this.onSave }
                />
                { staffReducer.snackMsg  && <ErrorText  text = { staffReducer.snackMsg } onClose = {() => this.props.setSnackMsgs(null) }  /> } 
                <DialogDismissButton onClick = { this.props.onClickDismissBtn } />
            
            </Paper>
        )
    }
}

function mapStateToProps(state) {
    return {
        staffReducer: state.staffReducer
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setSnackMsgs:   (msg)           => dispatch(setSnackMsgs(msg)),
        addStaff:       (url, body, cb) => dispatch(addStaff(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddStaff);