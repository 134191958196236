import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { App, Auth } from '../pages';
import { storageKeys } from '../lib/values';

const PRoutes = () => {
    const [ appToken, setAppToken ] = useState();

    useEffect(() => {
        const t = sessionStorage.getItem(storageKeys.appToken);
        t && t.trim() !== '' && setAppToken(t);
    }, []);
    
    useEffect(() => {
        appToken && appToken.trim() !== '' && sessionStorage.setItem(storageKeys.appToken, appToken)
    }, [appToken]);
    
    return (
        <BrowserRouter>
            <Routes>
                { appToken && <Route path ="/app" element = { <App /> } />  }
                { !appToken && <Route path ="/auth" element = { <Auth /> } /> }
                <Route path ="*" element = { <Navigate to = { appToken ? '/app' : '/auth' } /> } />
            </Routes>
        </BrowserRouter>
    )
}

export default PRoutes;