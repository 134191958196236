import React, { Fragment } from 'react';
import { Typography, FormControlLabel, Checkbox,  } from '@mui/material';
import PropTypes from 'prop-types';

import '../styles.css';
import { PrimaryButton } from '../../../../components/buttons';


////////////////////////////////////////////////////////////////////////////////////////////////////
//     Footer file info
///////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////
//     Default exported Component
///////////////////////////////////////////////////////////////////////////////////////////////////
export const UploadBtn = (props) => {
    let filePicker = null;
    return (
        <Fragment>
            <PrimaryButton 
                buttonText  = "Choose Image"
                buttonColor = "default"
                onClick     = { () => filePicker.click() } 
            />
            <Typography className="upload-text" style = {{color: props.uploadErr ? '#cc0000' : '#14a687'}}> { props.uploadErr || 'Maximum upload file size: 2 MB' } </Typography>
            <FormControlLabel
                control = {
                    <Checkbox
                        color = { "secondary" }
                        value = "icon"
                    />
                }
                onChange = {(e, v) => props.onChangeUploadAsBtn(v) }
                label= {<Typography style = {{color: '#999', fontSize: "1rem"}}> Upload image as icon </Typography>}
            />
                
            <input 
                type     = "file" 
                style    = { {display: 'none'} } 
                ref      = { filePickerRef => filePicker = filePickerRef }
                onChange = { event => props.onChangeFilePicker(filePicker.files) }
            />
        </Fragment>
    )
}

UploadBtn.propTypes = {
    onChangeFilePicker: PropTypes.func,
}