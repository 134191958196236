import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';
import './style.css';

const StartAdornmentComponent = (props) => {
    return (
        <InputAdornment position="start">
            { props.iconComponent }
        </InputAdornment>
    )
}

const LeftIconInput = (props) => {
    return (
        <TextField
            type            = { props.inputType }
            placeholder     = { props.placeholder }
            error           = { props.hasError }
            fullWidth       = { props.fullWidth }
            autoFocus       = {true}
            value           = { props.value }
            onChange        = { (e) => props.onChangeText(e.target.value) }
            onKeyPress      = { (e) => props.onKeyPress(e) }
            className       = { `left-icon-input-container ${ props.containerClasses }` }
            InputProps      = {{ startAdornment: <StartAdornmentComponent {...props} /> }}
        />
    )
}

LeftIconInput.propTypes = {
    iconComponent:    PropTypes.node,
    inputType:        PropTypes.string,
    placeholder:      PropTypes.string,
    hasError:         PropTypes.bool,
    fullWidth:        PropTypes.bool,
    onChangeText:     PropTypes.func.isRequired,
    onKeyPress:       PropTypes.func,
    containerClasses: PropTypes.string,
}

LeftIconInput.defaultProps = { 
    inputType:        'text',
    hasError:         false,
    fullWidth:        true,
    containerClasses: '',
}

export default LeftIconInput;