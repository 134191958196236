import NEWS_CONST from './news-constants';


let initial_state = {
    news:           [],
    selectedNews:   null,
    fetchingNews:   false,
    updatingNews:   false,
    togglePubNews:  false,
    deletingNews:   false,
    snackMsg:       null,
}

export const newsReducer = (state = initial_state, action) => {
    switch(action.type){

/////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
        case NEWS_CONST.EMPTY_NEWS_LIST: {
            return {
                ...state,
                news: [],
            }
        }
        case NEWS_CONST.SET_SELECTED_NEWS: {
            return {
                ...state,
                selectedNews: action.payload,
            }
        }

        case NEWS_CONST.SET_SNACK_MSG: {
            return {
                ...state,
                snackMsg: action.payload,
            }
        }

/////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
        case NEWS_CONST.UPDATE_NEWS_START: {
            return {
                ...state,
                updatingNews: true,
                snackMsg: null,
            }
        }
        case NEWS_CONST.UPDATE_NEWS_SUCCESS: {
            return {
                ...state,
                updatingNews: false,
            }
        }
        case NEWS_CONST.UPDATE_NEWS_FAIL: {
            return {
                ...state,
                updatingNews: false,
                snackMsg: action.payload,
            }
        }

/////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
        case NEWS_CONST.FETCHING_NEWS_START: {
            return {
                ...state,
                fetchingNews: true,
                snackMsg: null,
            }
        }
        case NEWS_CONST.FETCHING_NEWS_SUCCESS: {
            return {
                ...state,
                fetchingNews: false,
                news: [...state.news, ...action.payload]
            }
        }
        case NEWS_CONST.FETCHING_NEWS_FAIL: {
            return {
                ...state,
                fetchingNews: false,
                snackMsg: action.payload,
            }
        }

/////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
        case NEWS_CONST.TOGGLE_PUB_NEWS_START: {
            return {
                ...state,
                togglePubNews: true,
                snackMsg: null,
            }
        }
        case NEWS_CONST.TOGGLE_PUB_NEWS_SUCCESS: {
            return {
                ...state,
                togglePubNews: false,
            }
        }
        case NEWS_CONST.TOGGLE_PUB_NEWS_FAIL: {
            return {
                ...state,
                togglePubNews: false,
                snackMsg: action.payload,
            }
        }

/////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
        case NEWS_CONST.DEL_NEWS_START: {
            return {
                ...state,
                deletingNews: true,
                snackMsg: null,
            }
        }
        case NEWS_CONST.DEL_NEWS_SUCCESS: {
            return {
                ...state,
                deletingNews: false,
            }
        }
        case NEWS_CONST.DEL_NEWS_FAIL: {
            return {
                ...state,
                deletingNews: false,
                snackMsg: action.payload,
            }
        }

                default: return state;
            }
        }