import MEDIA_CONST from './media-constants';
import action from '../action-generator';
import Utility from '../../lib/utility';

export const setTab             = (tabValue)    => action(MEDIA_CONST.SET_MM_TAB, tabValue);
export const selectFile         = (file)        => action(MEDIA_CONST.SELECT_FILE, file);
export const setSnackMsg        = (msg)         => action(MEDIA_CONST.SET_SNACK_MSG, msg);
export const setFileBase64      = (base64)      => action(MEDIA_CONST.SET_FILE_BASE64, base64);
export const clearMediaManager  = ()            => action(MEDIA_CONST.CLEAR_MEDIA_MANAGER, null);
export const setUploadProgress  = (progress)    => action(MEDIA_CONST.SET_UPLOAD_PROGRESS, progress);

export const fetchImagesList   = (url, body, cb) => asyncAction(url, body, cb, 'fetchImages');
export const refreshImagesList = (url, body, cb) => asyncAction(url, body, cb, 'refreshImages');
export const fetchIconsList    = (url, body, cb) => asyncAction(url, body, cb, 'fetchIcons');
export const refreshIconsList  = (url, body, cb) => asyncAction(url, body, cb, 'refreshIcons');
export const deleteFile        = (url, body, cb) => asyncAction(url, body, cb, 'deleteFile');
export const moveFile          = (url, body, cb) => asyncAction(url, body, cb, 'moveFile');


/***************************************************************************************************
 *               Helper method for async actions
 ***************************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'fetchImages': {
            startAction     = MEDIA_CONST.FETCH_IMAGES_START;
            successAction   = MEDIA_CONST.FETCH_IMAGES_SUCCESS;
            failAction      = MEDIA_CONST.FETCH_IMAGES_FAIL;
            break;
        }
        case 'refreshImages': {
            startAction     = MEDIA_CONST.REFRESH_IMAGES_START;
            successAction   = MEDIA_CONST.REFRESH_IMAGES_SUCCESS;
            failAction      = MEDIA_CONST.REFRESH_IMAGES_FAIL;
            break;
        }
        case 'fetchIcons': {
            startAction     = MEDIA_CONST.FETCH_ICONS_START;
            successAction   = MEDIA_CONST.FETCH_ICONS_SUCCESS;
            failAction      = MEDIA_CONST.FETCH_ICONS_FAIL;
            break;
        }
        case 'refreshIcons': {
            startAction     = MEDIA_CONST.REFRESH_ICONS_START;
            successAction   = MEDIA_CONST.REFRESH_ICONS_SUCCESS;
            failAction      = MEDIA_CONST.REFRESH_ICONS_FAIL;
            break;
        }
        case 'deleteFile': {
            startAction     = MEDIA_CONST.DELETE_FILE_START;
            successAction   = MEDIA_CONST.DELETE_FILE_SUCCESS;
            failAction      = MEDIA_CONST.DELETE_FILE_FAIL;
            break;
        }
        case 'moveFile': {
            startAction     = MEDIA_CONST.MOVE_FILE_START;
            successAction   = MEDIA_CONST.MOVE_FILE_SUCCESS;
            failAction      = MEDIA_CONST.MOVE_FILE_FAIL;
            break;
        }
        default: {
            startAction     = MEDIA_CONST.FETCH_IMAGES_START;
            successAction   = MEDIA_CONST.FETCH_IMAGES_SUCCESS;
            failAction      = MEDIA_CONST.FETCH_IMAGES_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, files) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, files));
                cb && cb(null, files);
            }
        })
    }
}