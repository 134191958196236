import React , { Component } from 'react';
import { connect } from 'react-redux';
import { Paper, IconButton, CircularProgress, LinearProgress, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { MdDelete} from 'react-icons/md';

import { PrimaryButton } from '../../../../components/buttons';
import { RestrictedArea } from '../../../../components/container';
import { ConfirmDialog } from '../../../../components/dialog';
import { fetchCategories, deleteCategory } from '../../../../redux/category/category-actions';
import Utility  from '../../../../lib/utility';
import { endPoints, reqMethods } from '../../../../lib/values';

class CategoryList extends Component {

    state = {
        selectedCategory:   null,
        showConfirmDialog:  false,
    }

    componentDidMount() {
        this.fetchCategories()
    }

    fetchCategories = () => {
        this.props.fetchCategories(endPoints.categories.allCategories, Utility.httpRequest(reqMethods.get), (err, success) => {
            // do something after fetch
        })
    }

    deleteCategory = () => {
        this.setState({ showConfirmDialog: false, selectedCategory: null })
        const url = `${endPoints.categories.delCategory}/${this.state.selectedCategory._id}`;
        this.props.deleteCategory(url, Utility.httpRequest(reqMethods.delete), (err, success) => {
            if(success) this.fetchCategories()
        })
    }

    renderFullname = (category) => {
        if(!category){
            return ' - ';
        } else if(!category.createdBy){
            return 'Not Found';
        } else {
            return category.createdBy.fullname
        }
    }


    render(){
        const { categoryReducer } = this.props;
        return (
            <>
                <ConfirmDialog 
                    show      = { this.state.showConfirmDialog }
                    title     = { this.state.selectedCategory && `Delete ${this.state.selectedCategory.title_en}` }
                    message   = { "Are you sure you want to delete selected category?" }
                    onCancel  = { () => this.setState({ showConfirmDialog: false, selectedCategory: null }) }
                    onConfirm = { () => this.deleteCategory() }
                />
                <Paper classes = {{root: 'table-container-paper'}}>
                    { categoryReducer.asyncLoader && <LinearProgress color="secondary" style={{ height: 6 }}/>}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" colSpan={3}>Categories</TableCell>
                                <TableCell align="right" colSpan={4} >

                                    { categoryReducer.categories.length > 0 &&
                                    <PrimaryButton 
                                        buttonVariant   = "contained"
                                        buttonText      = "Refresh"
                                        style           = {{ marginRight: 5 }}
                                        onClick         = { this.fetchCategories }
                                        inProgress      = { categoryReducer.fetchingCats }
                                        buttonDisabled  = { categoryReducer.fetchingCats }
                                    />
                                    }
                                    <RestrictedArea>
                                        <PrimaryButton 
                                            buttonVariant   = "contained"
                                            buttonText      = "Create Category"
                                            onClick         = { this.props.onClickCreate }
                                        />
                                    </RestrictedArea>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Title (EN)    </TableCell>
                                <TableCell align="center">Title (UR)    </TableCell>
                                <TableCell align="center">Views         </TableCell>
                                <TableCell align="center">Created       </TableCell>
                                <TableCell align="center">Created By    </TableCell>
                                <RestrictedArea>
                                    <TableCell align="center">Delete        </TableCell>
                                </RestrictedArea>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { categoryReducer.categories.map(( category, i ) => 
                            <TableRow key = { category._id }>
                                <TableCell align="center">{ category.title_en }</TableCell>
                                <TableCell align="center">{ category.title_ur }</TableCell>
                                <TableCell align="center">{ category.views }</TableCell>
                                <TableCell align="center">{ Utility.formatFullDate(category.created) }</TableCell>
                                <TableCell align="center">{ this.renderFullname(category) }</TableCell>
                                <RestrictedArea>
                                    <TableCell align="center">
                                        <IconButton color="secondary" onClick = {() => this.setState({showConfirmDialog: true, selectedCategory: category}) }>
                                            <MdDelete />
                                        </IconButton>
                                    </TableCell>
                                </RestrictedArea>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    { (categoryReducer.fetchingCats && categoryReducer.categories.length === 0) &&  
                    <div style = {{ display: 'flex', padding: 50, justifyContent: 'center' }}>
                        <CircularProgress />
                    </div>
                    }
                </Paper>
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        categoryReducer: state.categoryReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchCategories:    (url, body, cb)    => dispatch(fetchCategories(url, body, cb)),
        deleteCategory:     (url, body, cb)     => dispatch(deleteCategory(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);