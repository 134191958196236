const data = [
    { title: "Dashboard",   slug: "dashboard", },
    { title: 'News',        slug: 'news',      },
    { title: 'Category',    slug: 'category',  },
    { title: 'Ranking',     slug: 'ranking',   },
    { title: 'Schedules',   slug: 'schedules', },
    { title: 'Media',       slug: 'media',     },
    { title: 'Employees',   slug: 'employees', },
]

export default data;