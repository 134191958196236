import React, { Fragment } from 'react';
import { Typography, LinearProgress} from '@mui/material';
import PropTypes from 'prop-types';
import '../styles.css';

export const UploadingImg = (props) => {
    return (
        <Fragment>
            <div className="uploading-img-container">
                <img className= { 'uploading-img' } src={ props.imgSource } alt="uploading"/>
            </div>
            <div>
                <Typography style={{ fontFamily: 'Helvetica_Roman', }} >{ props.progressPercent }%</Typography>
            </div>
            <div style={{width: 200}}>
                <LinearProgress value={ props.progressPercent } color="primary" variant = "determinate"/>
            </div>
        </Fragment>
    )
}

UploadingImg.propTypes = {
    imgSource: PropTypes.string,
    progressPercent: PropTypes.number
}