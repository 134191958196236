import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppBar, Toolbar } from '@mui/material';

import { RightButtons, Title } from './components';
import { setContentView, setXsDrawerVisible } from '../../../redux/dom/dom-actions';
import Utility from '../../../lib/utility';
import './styles.css';

class PrimaryAppBar extends Component {
    render(){
        const { authReducer, domReducer } = this.props;
        return (
            <AppBar className="appbar" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                
                <Toolbar>
                    <Title  onClickMenuBtn = { () => this.props.setXsDrawerVisible(!domReducer.visibleXsDrawer) } />
                    <RightButtons 
                        userImage       = { authReducer.user.image }
                        userBtnText     = { authReducer.user && authReducer.user.fullname.split(' ')[0] }
                        onClicUserBtn   = { () => this.props.setContentView('profile') }
                        onClickLogoutBtn = { this.props.onLogout }
                    />
                </Toolbar>
            </AppBar>
        )
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer,
        domReducer: state.dom,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setContentView: (view) => dispatch(setContentView(view)), 
        setXsDrawerVisible: (visible)   => dispatch(setXsDrawerVisible(visible))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryAppBar);