export default {
    EMPTY_NEWS_LIST:        'EMPTY_NEWS_LIST',
    SET_SELECTED_NEWS:      'SET_SELECTED_NEWS',
    SET_SNACK_MSG:          'SET_SNACK_MSG',

    UPDATE_NEWS_START:      'UPDATE_NEWS_START',
    UPDATE_NEWS_SUCCESS:    'UPDATE_NEWS_SUCCESS',
    UPDATE_NEWS_FAIL:       'UPDATE_NEWS_FAIL',

    FETCHING_NEWS_START:    'FETCHING_NEWS_START',
    FETCHING_NEWS_SUCCESS:  'FETCHING_NEWS_SUCCESS',
    FETCHING_NEWS_FAIL:     'FETCHING_NEWS_FAIL',
    
    TOGGLE_PUB_NEWS_START:  'TOGGLE_PUB_NEWS_START',
    TOGGLE_PUB_NEWS_SUCCESS:'TOGGLE_PUB_NEWS_SUCCESS',
    TOGGLE_PUB_NEWS_FAIL:   'TOGGLE_PUB_NEWS_FAIL',

    DEL_NEWS_START:         'DEL_NEWS_START',
    DEL_NEWS_SUCCESS:       'DEL_NEWS_SUCCESS',
    DEL_NEWS_FAIL:          'DEL_NEWS_FAIL',
    
}