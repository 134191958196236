import React, { Component } from 'react';
import { connect } from 'react-redux';

import { SegmentContainer } from '../../../components/container';
import { CustomDialog } from '../../../components/dialog';
import { unMountStaffPage, fetchStaffList } from '../../../redux/staff/staff-actions';
import { EmployeesList, AddEmployee, LoginTable, UpdateStaff } from './segments';
import  Utility  from '../../../lib/utility';
import  { endPoints }  from '../../../lib/values';

class StaffView extends Component {

    state = {
        last_login:     [],
        editDialogView: null,
        selectedStaff:  null,
        showEditDialog: false,
    }

    editDialogView = {
        addStaff:       'addStaff',
        updateStaff:    'updateStaff',
        loginHistory:   'loginHistory'
    }

    componentDidMount(){
        this.fetchEmployees()
    }

    componentWillUnmount(){
        this.props.unMountStaffPage();
    }

    onDismissEditDialog = () => {
        this.setState({showEditDialog: false})
    }

    fetchEmployees = () => {
        this.props.fetchStaffList(endPoints.employees.fetchEmployees, Utility.httpRequest("GET"), (err, success) => {
            if(err) this.setState({snackMsg: err});
        })
    }

    renderDialogView = () => {
        switch(this.state.editDialogView){
            case this.editDialogView.addStaff: {
                return (
                    <AddEmployee
                        onAddSuccess        = { () => this.setState({showEditDialog: false}, this.fetchEmployees )} 
                        onClickDismissBtn   = { this.onDismissEditDialog } 
                    />
                )
            }
            case this.editDialogView.updateStaff: {
                return (
                    <UpdateStaff 
                        user                = { this.state.selectedStaff } 
                        onClickDismissBtn   = { this.onDismissEditDialog  }
                        refreshList         = { () => this.setState({showEditDialog: false}, this.fetchEmployees ) } 
                        onCompleteUpdate    = { () => this.setState({showEditDialog: false}) }
                    />
                )
            }

            case this.editDialogView.loginHistory: {
                return (
                    <LoginTable 
                        user                = { this.state.selectedStaff }
                        loginData           = { this.state.selectedStaff.last_login} 
                        onClickDismissBtn   = { this.onDismissEditDialog } 
                    />
                )
            } 
            default: return <p> Nothing to show </p>;
        }
    }

    render(){
        return(
            <SegmentContainer 
                pageTitle       = "Employees"
                onClickRefresh  = { this.fetchEmployees }
                addTooltip      = "New Employee"
                onClickAdd      = { () => this.setState({showEditDialog: true, editDialogView: this.editDialogView.addStaff })}>

                <CustomDialog 
                    show    = { this.state.showEditDialog }
                    onClose = { () => this.setState({showEditDialog: false}) }> 
                    { this.renderDialogView() }
                </CustomDialog>
                

                <EmployeesList
                    refreshList             = { this.fetchEmployees } 
                    onClickCreateBtn        = { ()              => this.setState({showEditDialog: true, editDialogView: this.editDialogView.addStaff })}
                    onClickUpdateStaff      = { (selectedStaff) => this.setState({selectedStaff, showEditDialog: true, editDialogView: this.editDialogView.updateStaff})}
                    onClickLoginDetailBtn   = { (selectedStaff) => this.setState({selectedStaff, showEditDialog: true, editDialogView: this.editDialogView.loginHistory}) }
                />
            </SegmentContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        staffReducer: state.staffReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        unMountStaffPage:   () => dispatch(unMountStaffPage()),
        fetchStaffList:     (url, body, cb) => dispatch(fetchStaffList(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffView);