import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import './styles.css';

const PrimaryButton = (props) => {
    return (
        <Button 
            color       = { props.buttonColor }
            variant     = { props.buttonVariant }
            size        = { props.buttonSize }
            disabled    = { props.buttonDisabled }
            fullWidth   = { props.buttonFullWidth }
            href        = { props.href }
            target      = { "_blank" }
            className   = { `btn-font ${props.buttonClasses}` }
            style       = { props.style }
            onClick     = { props.onClick }>
                { props.inProgress 
                ? <CircularProgress size = { props.pCircleSize } color = { props.pCircleColor } />
                : props.buttonText
                }
        </Button>
    )
}

PrimaryButton.propTypes = {
    buttonColor:        PropTypes.oneOf(['default', 'primary', 'secondary']),
    buttonSize:         PropTypes.oneOf(['small', 'medium', 'large']),
    buttonVariant:      PropTypes.oneOf(['text', 'outlined', 'contained', 'fab', 'extendedFab']),
    buttonFullWidth:    PropTypes.bool,
    buttonDisabled:     PropTypes.bool,
    buttonText:         PropTypes.string,
    buttonClasses:      PropTypes.string,
    href:               PropTypes.string,
    onClick:            PropTypes.func,
    pCircleSize:        PropTypes.number,
    pCircleColor:       PropTypes.oneOf(['default', 'primary', 'secondary']),
    inProgress:         PropTypes.bool,
    style:              PropTypes.object

}

PrimaryButton.defaultProps = {
    buttonColor:     'primary',
    buttonSize:      'large',
    buttonVariant:   'contained',
    buttonFullWidth: false,
    buttonDisabled:  false,
    buttonText:      'Click Me',
    pCircleSize:     24,
    pCircleColor:    'primary',
    buttonClasses:   '',
    inProgress:      false,
}

export default PrimaryButton;