export default  {
    SET_MM_TAB:                 'SET_MM_TAB',
    CLEAR_MEDIA_MANAGER:        'CLEAR_MEDIA_MANAGER',

    FETCH_IMAGES_START:         'FETCH_IMAGES_START',
    FETCH_IMAGES_SUCCESS:       'FETCH_IMAGES_SUCCESS',
    FETCH_IMAGES_FAIL:          'FETCH_IMAGES_FAIL',

    REFRESH_IMAGES_START:       'REFRESH_IMAGES_START',
    REFRESH_IMAGES_SUCCESS:     'REFRESH_IMAGES_SUCCESS',
    REFRESH_IMAGES_FAIL:        'REFRESH_IMAGES_FAIL',

    FETCH_ICONS_START:          'FETCH_ICONS_START',
    FETCH_ICONS_SUCCESS:        'FETCH_ICONS_SUCCESS',
    FETCH_ICONS_FAIL:           'FETCH_ICONS_FAIL',

    REFRESH_ICONS_START:        'REFRESH_ICONS_START',
    REFRESH_ICONS_SUCCESS:      'REFRESH_ICONS_SUCCESS',
    REFRESH_ICONS_FAIL:         'REFRESH_ICONS_FAIL',

    SET_SNACK_MSG:              'SET_SNACK_MSG',

    SELECT_FILE:                'SELECT_FILE',
    SET_UPLOAD_PROGRESS:        'SET_UPLOAD_PROGRESS',
    SET_FILE_BASE64:            'SET_FILE_BASE64',

    DELETE_FILE_START:          'DELETE_FILE_START',
    DELETE_FILE_SUCCESS:        'DELETE_FILE_SUCCESS',
    DELETE_FILE_FAIL:           'DELETE_FILE_FAIL',

    MOVE_FILE_START:            'MOVE_FILE_START',
    MOVE_FILE_SUCCESS:          'MOVE_FILE_SUCCESS',
    MOVE_FILE_FAIL:             'MOVE_FILE_FAIL',
    
}