export const apiHost =   "http://localhost:4000" //"https://api.sportsview.pk"  //"https://dev-api.sportsview.pk"; //
export const API_HOST = {
    LOCAL: 'http://localhost:4000',
    TEST:  'https://test-api.bazi.pk',
    PROD:  'https://api.bazi.pk',
}
export const cViews = {
    dashboard:      'dashboard',
    profile:        'profile',
    category:       'category',
    media:          'media',
    employees:      'employees',
    news:           'news',
    ranking:        'ranking',
    schedules:      'schedules',
    system:         'system',
    translator:     'translator'
}

export const storageKeys = {
    email:      'email',
    appToken:  'app_token',
}

export const reqMethods = {
    get:    'GET',
    post:   'POST',
    put:    'PUT',
    delete: 'DELETE',
}

export const perPage = {
    newsList: 15,
    mmFiles: 15,  // media manager files
}

export const endPoints = {
    news: {
        newsList:           '/manage/news/list',
        updateNews:         '/manage/news/update',
        deleteNews:         '/manage/news/delete',
        togglePubNews:      '/manage/news/toggle-publish',

    },
    categories: {
        allCategories:      '/manage/category/view-all',
        createCategory:     '/manage/category/create',
        delCategory:        '/manage/category/delete',
    },
    employees: {
        addEmployee:        '/manage/employees/add-new',
        fetchEmployees:     '/manage/employees/list',
        updateEmployee:     '/manage/employees/update',
        suspendEmployee:    '/manage/employees/suspend',
        unsuspendEmployee:  '/manage/employees/un-suspend',
        deleteEmployee:     '/manage/employees/delete',
    },
    media: {
        fetchImages:        '/manage/media/list?fType=images',
        fetchIcons:         '/manage/media/list?fType=icons',
        uploadFile:         '/manage/media/upload',
        deleteFile:         '/manage/media/delete',
        moveFile:           '/manage/media/move',
    },
    auth: {
        login:              '/auth/login',
        genAuthCode:        '/auth/generate-auth-code',
        resetPassword:      '/auth/reset-password',
        myProfile:          '/auth/my-profile',
        updateProfile:      '/auth/update-profile',
        updateMyImg:        '/auth/update-image',
    },
    dashboard: {
        latestData:         '/manage/dashboard/latest-data',
    }
}