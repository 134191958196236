import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme =  createTheme({
    palette: {
        primary: {
            main: '#aa1d43',
        },
        secondary: {
            main: '#cc0000'
        }

    },
    mixins: {
        toolbar: {
            minHeight: 72
        }
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                fontSize: '1.5rem',
                
            },
            containedPrimary: {
                backgroundColor: '#aa1d43',
            },
            label: {
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontWeight: '500',
                color: '#fff'
            }
        }
    }
    
});

const PrimaryContainer = (props) => {
    return (
        <ThemeProvider theme = { theme }>
            { props.children }
            {/* <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                { props.children }
            </div> */}
        </ThemeProvider>
    )
}

export default PrimaryContainer;