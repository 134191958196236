import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { MdExpandLess, MdExpandMore  } from 'react-icons/md';

class PrimaryListItem extends Component {

    state = { open: false }

    onClick = () => {
        const { open } = this.state;
        this.setState({open: !open});
    }

    render() {
        return (
            <>
                <ListItem 
                    button      = { this.props.button } 
                    divider     = { this.props.divider || true }
                    onClick     = { this.props.onClick || this.onClick }>
                    
                    { this.props.icon &&
                        <ListItemIcon style = { this.props.iconStyles }>{ this.props.icon }</ListItemIcon>
                    }

                    <ListItemText 
                        primary                 = { this.props.primaryText }
                        primaryTypographyProps  = { this.props.prTextProps }
                    />
                    { !this.props.expandAble ? null : this.state.open ? <MdExpandLess /> : <MdExpandMore /> }
                </ListItem>
                { this.props.expandAble &&
                <Collapse 
                    in      = { this.state.open } 
                    timeout = "auto" 
                    unmountOnExit>
                    { this.props.children }
                </Collapse>
                }
            </>
        )
    }
}

PrimaryListItem.propTypes = {
    button:             PropTypes.bool,
    expandAble:         PropTypes.bool,
    onClick:            PropTypes.func,
    icon:               PropTypes.node,
    primaryText:        PropTypes.string,
    prTextProps:        PropTypes.object,
}
PrimaryListItem.defaultProps = {
    button:             true,
    expandAble:         false,
    icon:               null,
    primaryText:        "List Item",
}

export default PrimaryListItem;