import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Card, CardHeader, CardMedia, CardContent, Table, TableBody, TableRow, TableCell, Menu, MenuItem, CircularProgress } from '@mui/material';
import { MdMoreVert } from 'react-icons/md';
import Utility from '../../lib/utility';


class UserCard extends React.Component {

    state = {
        anchorEl: null,
    }

    closeMenu   = () => this.setState({ anchorEl: null })
    showMenu    = e  => this.setState({ anchorEl: e.currentTarget })

    onClickMenuItem = action => {
        this.closeMenu();
        this.props.onClickMenuItem(action);
    }

    render(){
        const { anchorEl } = this.state;
        const { user } = this.props;


        return (
            <>
                <Menu
                    open        = { Boolean(anchorEl)}
                    anchorEl    = { anchorEl }
                    onClose     = { this.closeMenu }>
                    <MenuItem onClick = { () => this.onClickMenuItem('update') }>Update</MenuItem>
                    <MenuItem onClick = { () => this.onClickMenuItem('loginDetail') }>Login Detail</MenuItem>
                    <MenuItem onClick = { () => this.onClickMenuItem('resetPass') }>Reset Password</MenuItem>
                    <MenuItem onClick = { () => this.onClickMenuItem(user.suspended ? 'unsuspend' : 'suspend') }>{ user.suspended ? "Un-suspend" : "Suspend" }</MenuItem>
                    <MenuItem onClick = { () => this.onClickMenuItem('delete') }>Delete</MenuItem>
                </Menu>

                <Card>
                    <CardHeader
                        title       = { Utility.subString(user.fullname, 15) }
                        subheader   = {  Utility.UserRoleToStr(user.role) }
                        style       = { styles.cardHead }
                        titleTypographyProps = {{ style: { fontSize: 18, fontWeight: 'bold' } }}
                        action      = {
                            <IconButton color="secondary" onClick = { this.showMenu } disabled = { this.props.inProgress }>
                                { !this.props.inProgress 
                                ? <MdMoreVert />
                                : <CircularProgress size = { 24 } color = "secondary" />
                                }
                            </IconButton>
                        }
                    />
                    <CardMedia
                        style   = {{ height: 200, objectFit: 'scale-down', padding: "8px 0px"  }}
                        component = "img"
                        image   = { user.image || '/assets/images/user.png'}
                        title   = { user.fullname } 
                    />

                    <CardContent style = { styles.cardContent }>
                        <Table size = "small">
                            <TableBody>
                                <TableRow hover>
                                    <TableCell style = { styles.bold }>Mobile</TableCell>
                                    <TableCell>{ user.mobile }</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell style = { styles.bold }>Status</TableCell>
                                    <TableCell style={{ color: user.suspended ? '#cc0000' : 'green', fontWeight: 'bold' }}>{ user.suspended ? "Suspended" : "Not Suspended"  }</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell style = { styles.bold }>Created</TableCell>
                                    <TableCell>{ Utility.formatDateOnly(user.created_at) }</TableCell>
                                </TableRow>
                                <TableRow hover>
                                    <TableCell style = { styles.bold }>Created By</TableCell>
                                    <TableCell>{ user.created_by && user.created_by.fullname }</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </>
        )
    }
}

const styles = {
    bold: {
        fontWeight: 'bold',
        fontSize: 12,
    },

    cardContent: {
        paddingBottom: 0 
    },

    cardHead: {
        backgroundColor: 'rgba(0,0,0,0.08)'
    }
}

UserCard.propTypes = {
    user:           PropTypes.object,
    onClickMenuItem: PropTypes.func,
    inProgress:     PropTypes.bool,
}

UserCard.defaultProps = {
    user:           {},
    inProgress:     false,
    onClickMenuItem:  () => console.log('mising props onClickMenuItem'),
}

export default UserCard;