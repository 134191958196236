import React from 'react';
import { Paper, Typography, IconButton } from '@mui/material';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

import './styles.css';

const ErrorText = (props) => {
    return (
        <Paper classes = {{ root: 'err-text-container' }} style = {{backgroundColor: props.lightMode ? '#ffffff' : '#f8f8f8'}}>
            <div className="heading-text-right-bar"></div>
            <Typography variant="body1" className="err-text">{ props.text  }</Typography>
            <IconButton classes={{root: 'err-text-close-btn'}} onClick = { props.onClose }>
                <MdClose />
            </IconButton>
        </Paper>
    )
}

ErrorText.propTypes = {
    text:    PropTypes.string.isRequired,
    onClose: PropTypes.func,
}
ErrorText.defaultProps = {
    text:    'No text to display',
    onClose: () => alert('Not Configured...'),
}

export default ErrorText;