import axios from 'axios';
import moment from 'moment';
import { storageKeys, API_HOST } from './values';
import { setSessionData } from '../redux/auth/auth-actions';
import store from '../redux/store';

export default class Utility{
 
    static httpRequest(method, jsonBody, contentType){
        return {
            method: method,
            headers: {
                'Content-Type'  :   contentType ? contentType : 'application/json',
                'Authorization' :   'Bearer ' + sessionStorage.getItem(storageKeys.appToken)
            },
            timeout: 15000,
            data: jsonBody ? JSON.stringify(jsonBody) : undefined
        }
    }

    static _fetch(url, body, cb){
        url = process.env.REACT_APP_API_HOST + url;
        axios({
            url,
            ...body
        })
        .then((response) => {
            cb && cb(null, response.data);
            
        })
        .catch(error => {
            const { response } = error;
        
            if(error.code === 'ECONNABORTED'){
                cb && cb('Request Timeout. Try again', null);
            } else if(response === undefined){
                cb && cb('Network Error', null);
            } else if(response.status === 400) {
                store.dispatch(setSessionData({ msg: 'Your session has expired, Please login to continue'}));
            }else {
                cb && cb(response.data.err || "Unknown error", null);
            } 
        });
    }

    static uploadFile(endPoint, formData, onProgress, onComplete){
        const reqUrl = API_HOST.LOCAL + endPoint;
        axios.post(reqUrl, formData, {
            headers: {
            'Content-Type' : 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem(storageKeys.appToken)
            },
            timeout: 15000,
            onUploadProgress: onProgress && onProgress
        })
        .then(res => onComplete && onComplete(null, res.data))
        .catch(err => onComplete && onComplete(err.response.data ? err.response.data.err : 'Some thing went wrong. try again.', null));
    }

    static UserRoleToStr(role){
        switch(role){
            case 1:  return  'Admin'
            case 2:  return  'Manager'
            case 3:  return  'Publisher'
            case 4:  return  'Editor'
            default: return  'Unknown'
        }
    }

    static formatFullDate(fullDate){
        return moment(fullDate).format("hh:mm A - MMM DD, YYYY");
    }
    
    static formatDateOnly(fullDate){
        return moment(fullDate).format("MMM DD, YYYY");
    }

    static timeAgoFormat(date){
        return moment(date).fromNow();
    }

    static subString(str, length){
        return str.length > length 
        ? str.substring(0, length) + '...' 
        : str.substring(0, length);
    }

    static imageDimension(image, cb) {
        let newImg = new Image();
        newImg.src = window.URL.createObjectURL(image);
        newImg.onload  = (e) => cb(null, newImg);
        newImg.onerror = (e) => cb("Invalid image file", null);
    }

}