export default {
    SET_SNACK_MSG:              'SET_SNACK_MSG',
    UNMOUNT_CATEGORIES:         'UNMOUNT_CATEGORIES',
    
    CREATE_CATEGORY_START:      'CREATE_CATEGORY_START',
    CREATE_CATEGORY_SUCCESS:    'CREATE_CATEGORY_SUCCESS',
    CREATE_CATEGORY_FAIL:       'CREATE_CATEGORY_FAIL',

    FETCH_CATEGORIES_START:     'FETCH_CATEGORIES_START',
    FETCH_CATEGORIES_SUCCESS:   'FETCH_CATEGORIES_SUCCESS',
    FETCH_CATEGORIES_FAIL:      'FETCH_CATEGORIES_FAIL',

    DELETE_CATEGORY_START:      'DELETE_CATEGORY_START',
    DELETE_CATEGORY_SUCCESS:    'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAIL:       'DELETE_CATEGORY_FAIL',

}