import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { IoMdAdd, IoMdRefresh } from 'react-icons/io';

const SegmentContainer = (props) => {
 return (
    <>
     <div className="segment-container">
        { props.pageTitle &&
            <div className='segment-header'>
                <div className="segment-title">
                    { props.pageTitle }
                </div>
                { props.refreshBtn &&
                <Tooltip title={ props.refreshTooltip }>
                    <IconButton color={ "primary" } onClick = { props.onClickRefresh }>
                        <IoMdRefresh />
                    </IconButton>
                </Tooltip>
                }
                { props.addBtn && 
                <Tooltip title = { props.addTooltip }>
                    <IconButton color={ "primary" } onClick = { props.onClickAdd }>
                        <IoMdAdd />
                    </IconButton>
                </Tooltip>
                }
            </div>
        }
         <div style={{padding: 10}}>
                { props.children }
         </div>
     </div>
    </>
 )
}

SegmentContainer.propTypes = {
    pageTitle:      PropTypes.string,
    refreshTooltip: PropTypes.string,
    addTooltip:     PropTypes.string,
    refreshBtn:     PropTypes.bool,
    addBtn:         PropTypes.bool,
    onClickRefresh: PropTypes.func,
    onClickAdd:     PropTypes.func,
}
SegmentContainer.defaultProps = {
    refreshTooltip: "Refresh",
    addTooltip:     "Add New",
    refreshBtn:     true,
    addBtn:         true,
    onClickRefresh: () => console.error("Refresh function not defined"),
    onClickAdd:     () => console.error("Add function not defined"),
}

export default SegmentContainer;