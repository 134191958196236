import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CategoryList, CreateCategory } from './container';
import { SegmentContainer } from '../../../components/container';
import { CustomDialog, Snackbar } from '../../../components/dialog';
import { unmountCategories,  createCategory, deleteCategory, setSnackMsg } from '../../../redux/category/category-actions';

class CategoryView extends Component {

    state = { 
        showCreateDialog: false,
     }

    componentWillUnmount(){
        this.props.unmountCategories();
    }

    render(){
        const { categoryReducer } = this.props;
        return(
            <SegmentContainer pageTitle="Manage Categories">

                <Snackbar 
                    open    = { Boolean(categoryReducer.snackMsg) }
                    message = { categoryReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />

                <CustomDialog 
                    show    = { this.state.showCreateDialog }
                    onClose = {() => this.setState({showCreateDialog: false})}>
                    <CreateCategory 
                        onSave = {() => this.setState({showCreateDialog: false})}
                        onClickDismiss = { () => this.setState({showCreateDialog: false}) }
                    />
                </CustomDialog>

                <CategoryList 
                    onClickCreate = { () => this.setState({showCreateDialog: true}) }
                />
            </SegmentContainer>
        )
    }
}

function mapStateToProps(state){
    return {
        categoryReducer: state.categoryReducer
    }
}

function mapDispatchToProp(dispatch){
    return {
        setSnackMsg:        (msg)           => dispatch(setSnackMsg(msg)),
        unmountCategories:  ()              => dispatch(unmountCategories()),
        createCategory:     (url, body, cb) => dispatch(createCategory(url, body, cb)),
        deleteCategory:     (url, body, cb) => dispatch(deleteCategory(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProp)(CategoryView);