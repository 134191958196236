import React, { Component } from 'react';
import {  Grid, Paper, Avatar, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, ListSubheader, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { MdAssignment, MdPermMedia, MdVerifiedUser, MdKeyboardArrowRight, MdViewAgenda } from 'react-icons/md';

import { CountBadge } from './components';
import './styles.css';

import { SegmentContainer } from '../../../components/container';
import Utility from '../../../lib/utility';
import { endPoints, reqMethods } from '../../../lib/values';


class Dashboard extends Component {
    
    state = {
        latestData: null,
        loadingData: false,
    }

    componentDidMount(){
        this.setState({loadingData: true});
        Utility._fetch(endPoints.dashboard.latestData, Utility.httpRequest(reqMethods.get), (err, data) => {
            this.setState({loadingData: false});
            if(err === null) this.setState({latestData: data})
        })
        
        
    }

////////////////////////////////////////////////////////////////////////////////////////
///////////    render counters => totalNews, totalMedia, total cats etc
///////////////////////////////////////////////////////////////////////////////////////
    renderCounterBadges = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <CountBadge
                        icon            = { <MdAssignment size={48} /> }
                        counts          = { this.state.latestData.totalNews }
                        headingText     = "News"
                        iconBgColor     = "#118C72"
                        headingBgColor  = "#14a687"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <CountBadge
                        icon            = { <MdPermMedia size={48} /> }
                        counts          = { this.state.latestData.totalMedia }
                        headingText     = "Media"
                        iconBgColor     = "#273791"
                        headingBgColor  = "#3f51b5"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <CountBadge
                        icon            = { <MdViewAgenda size={48} /> }
                        counts          = { this.state.latestData.totalCategories }
                        headingText     = "Categories"
                        iconBgColor     = "#6B006B"
                        headingBgColor  = "#800080"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <CountBadge
                        icon            = { <MdVerifiedUser size={48} /> }
                        counts          = { this.state.latestData.totalStaff }
                        headingText     = "Staff"
                        iconBgColor     = "#B20000"
                        headingBgColor  = "#CC0000"
                    />
                </Grid>
            </Grid>
        )
    }

////////////////////////////////////////////////////////////////////////////////////////
///////////    render contents => latest news, categories, and staff
///////////////////////////////////////////////////////////////////////////////////////
    renderLatestContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item lg ={6} md={12} xs={12}>
                    { this.renderLatestNews() }
                </Grid>
                <Grid item lg ={3} md={6} xs={12}>
                    {this.renderPopularCats() }
                </Grid>
                <Grid item lg ={3} md={6} xs={12}>
                    { this.renderStaffs() }
                </Grid>
            </Grid>
        )
    }

////////////////////////////////////////////////////////////////////////////////////////
///////////        render latest news
///////////////////////////////////////////////////////////////////////////////////////
    renderLatestNews = () => {
        return (
            <Paper>
                <List disablePadding>
                    <ListSubheader> 
                        Latest News
                    </ListSubheader>
                    { this.state.latestData.latestNews.map(lNews =>
                        <ListItem divider button key={lNews._id}>
                        <ListItemAvatar>
                            <Avatar src = { lNews.feature_img } />
                        </ListItemAvatar>
                        <ListItemText primary={ lNews.title_en } secondary={ lNews.lastEditBy.fullname +', '+ Utility.timeAgoFormat(lNews.lastEdit) } />
                        <ListItemSecondaryAction>
                            <IconButton>
                                <MdKeyboardArrowRight />
                            </IconButton>
                        </ListItemSecondaryAction>
                        </ListItem>
                    ) }
                    
                </List>
            </Paper>
        )
    }

////////////////////////////////////////////////////////////////////////////////////////
///////////     render popular categories
///////////////////////////////////////////////////////////////////////////////////////
    renderPopularCats = () => {
        return (
            <Paper>
                <List disablePadding>
                    <ListSubheader> 
                        Popular Categories
                    </ListSubheader>
                    { this.state.latestData.popularCategories.map(category => 
                     <ListItem divider button key={category._id}>
                        <ListItemText primary={ category.title_en } secondary= { category.views + ' views' } />
                    </ListItem>   
                    ) }
                </List>
            </Paper>
        )
    }

////////////////////////////////////////////////////////////////////////////////////////
///////////         render Staff List
///////////////////////////////////////////////////////////////////////////////////////
    renderStaffs = () => {
        return (
            <Paper>
                <List disablePadding>
                    <ListSubheader> 
                        Staff Member
                    </ListSubheader>
                    { this.state.latestData.staff.map(staffMember => 
                        <ListItem divider button disabled = { staffMember.suspended } key={staffMember._id}>
                            <ListItemAvatar>
                                <Avatar src={ Utility.addHostToImage(staffMember.image) } />
                            </ListItemAvatar>
                            <ListItemText primary={ staffMember.fullname } secondary={ staffMember.suspended ? 'Suspended' : Utility.accessLevelToStr(staffMember.access_level) } />
                        </ListItem>   
                    ) }
                </List>
            </Paper>
        )
    }

////////////////////////////////////////////////////////////////////////////////////////
///////////         render data receive from server
///////////////////////////////////////////////////////////////////////////////////////
    renderContent = () => {
        return (
            <>
                { this.renderCounterBadges() }
                { this.renderLatestContent() }
            </>
        )
        
    }

////////////////////////////////////////////////////////////////////////////////////////
///////////         Loader component
///////////////////////////////////////////////////////////////////////////////////////
    renderLoader = () => {
        return (
            <div className="dashboard-loader-container">
                <CircularProgress />
            </div>
        )
    }

////////////////////////////////////////////////////////////////////////////////////////
///////////         Main render function
///////////////////////////////////////////////////////////////////////////////////////
    render(){
        return(
            <SegmentContainer pageTitle="Dashboard">
                { this.state.loadingData && this.renderLoader() }
                { this.state.latestData !== null && this.renderContent() }
            </SegmentContainer>
        )
    }
}

export default connect(null, null)(Dashboard);