import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class RestrictedArea extends Component {

    state = {
        allowUsers: [],
        canRender: false,
    }

    componentWillMount() {
        this.setAllowUsers();
    }

    setAllowUsers = () => {
        let { allowUsers } = this.state;
        const { admin, manager, publisher, editor } = this.props;
        if(admin     === true) allowUsers.push(1);
        if(manager   === true) allowUsers.push(2);
        if(publisher === true) allowUsers.push(3);
        if(editor    === true) allowUsers.push(4);
        this.setState({allowUsers}, () => {
            this.canRenderChildren();
        });
    }

    canRenderChildren = () => {
        const canRender = this.state.allowUsers.includes(this.props.authReducer.user.role);
        this.setState({ canRender });
    }

    render(){
        return (this.state.canRender ? this.props.children : null)
    }
}

RestrictedArea.defaultProps = {
    admin:      true,
    manager:    false, 
    publisher:  false,
    editor:     false,
}

RestrictedArea.propTypes = {
    admin:      PropTypes.bool,
    manager:    PropTypes.bool, 
    publisher:  PropTypes.bool,
    editor:     PropTypes.bool,
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer
    }
}

export default connect(mapStateToProps, null)(RestrictedArea);