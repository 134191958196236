import STAFF_CONST from './staff-constants';
import action from '../action-generator';
import Utility from '../../lib/utility';

export const unMountStaffPage = () => {
    return action(STAFF_CONST.UNMOUNT_STAFF_PAGE, null);
}

export const setSnackMsgs = (msg) => {
    return action(STAFF_CONST.SET_SNACK_MSG, msg);
}

export const setStaffSuspension = (susData) => {
    return action(STAFF_CONST.SET_STAFF_SUSPENSION, susData);
}

export const addStaff       = (url, body, cb) => asyncAction(url, body, cb, 'addStaff');
export const updateStaff    = (url, body, cb) => asyncAction(url, body, cb, 'updateStaff');
export const fetchStaffList = (url, body, cb) => asyncAction(url, body, cb, 'fetchStaffList');
export const suspendStaff   = (url, body, cb) => asyncAction(url, body, cb, 'suspendStaff');
export const deleteStaff    = (url, body, cb) => asyncAction(url, body, cb, 'deleteStaff');

/***************************************************************************************************
 *              Helper method for async actions
 ***************************************************************************************************/
const asyncAction = (url, body, cb, actionType) => {
    let startAction = null, successAction = null, failAction = null;
    switch(actionType){
        case 'fetchStaffList': {
            startAction     = STAFF_CONST.FETCH_STAFF_LIST_START;
            successAction   = STAFF_CONST.FETCH_STAFF_LIST_SUCCESS;
            failAction      = STAFF_CONST.FETCH_STAFF_LIST_FAIL;
            break;
        }
        case 'addStaff': {
            startAction     = STAFF_CONST.ADD_NEW_STAFF_START;
            successAction   = STAFF_CONST.ADD_NEW_STAFF_SUCCESS;
            failAction      = STAFF_CONST.ADD_NEW_STAFF_FAIL;
            break;
        }
        case 'updateStaff': {
            startAction     = STAFF_CONST.UPDATE_STAFF_START;
            successAction   = STAFF_CONST.UPDATE_STAFF_SUCCESS;
            failAction      = STAFF_CONST.UPDATE_STAFF_FAIL;
            break;
        }
        case 'suspendStaff': {
            startAction     = STAFF_CONST.SUSPEND_STAFF_START;
            successAction   = STAFF_CONST.SUSPEND_STAFF_SUCCESS;
            failAction      = STAFF_CONST.SUSPEND_STAFF_FAIL;
            break;
        }
        case 'deleteStaff': {
            startAction     = STAFF_CONST.DELETE_STAFF_START;
            successAction   = STAFF_CONST.DELETE_STAFF_SUCCESS;
            failAction      = STAFF_CONST.DELETE_STAFF_FAIL;
            break;
        }
        default: {
            startAction     = STAFF_CONST.FETCH_STAFF_LIST_START;
            successAction   = STAFF_CONST.FETCH_STAFF_LIST_SUCCESS;
            failAction      = STAFF_CONST.FETCH_STAFF_LIST_FAIL;
            break;
        }
    }
    return (dispatch) => {
        dispatch(action(startAction, null));
        Utility._fetch(url, body, (err, success) => {
            if(err){
                dispatch(action(failAction, err));
                cb && cb(err, null);
            } else {
                dispatch(action(successAction, success));
                cb && cb(null, success);
            }
        })
    }
}