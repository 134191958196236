import React from 'react';
import { Popover, Paper } from '@mui/material';
import PropTypes from 'prop-types';

const PrimaryPopOver = (props) => {
    return (
        <Popover
            open         = { props.open }
            anchorEl     = { props.anchorEl }
            onClose      = { props.onClose }
            anchorOrigin = {{
                vertical:   props.anchorOriginVertical,
                horizontal: props.anchorOriginHorizontal,
            }}
            transformOrigin= {{
                vertical:   props.transformOriginVertical,
                horizontal: props.transformOriginHorizontal,
            }}>
                <Paper style={{padding: 15}}>{ props.text }</Paper>
        </Popover>
    )
}

PrimaryPopOver.defaultProps = {
    open:       false,
    text:       'No text to dipslay',
    anchorEl:   null,
    onClose:    () => console.log('Not configured'),
    anchorOriginVertical:       'top',
    anchorOriginHorizontal:     'left',
    transformOriginVertical:    'bottom',
    transformOriginHorizontal:  'right',
    
}
PrimaryPopOver.propTypes = {
    open:       PropTypes.bool,
    text:       PropTypes.string,
    onClose:    PropTypes.func,
    anchorEl:   PropTypes.object,
    anchorOriginVertical:       PropTypes.string,
    anchorOriginHorizontal:     PropTypes.string,
    transformOriginVertical:    PropTypes.string,
    transformOriginHorizontal:  PropTypes.string,
}

export default PrimaryPopOver;