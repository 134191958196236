import CATEGORY_CONST from './category-constants';

let initial_state = {
    categories:     [],
    asyncLoader:    false,
    fetchingCats:   false,
    snackMsg:       null,
}
export const categoryReducer = (state = initial_state, action) => {
    
    switch(action.type){
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  set snack
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case CATEGORY_CONST.SET_SNACK_MSG: {
            return {
                ...state,
                snackMsg: action.payload,
            }
        }
        
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  Unmount category page
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case CATEGORY_CONST.UNMOUNT_CATEGORIES: {
            return initial_state
        }
    
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  Fetch category list
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case CATEGORY_CONST.FETCH_CATEGORIES_START: {
            return {
                ...state,
                fetchingCats: true,
                snackMsg: null,
            }
        }
        case CATEGORY_CONST.FETCH_CATEGORIES_SUCCESS: {
            return {
                ...state,
                fetchingCats: false,
                categories: action.payload || [], // array of categories
            }
        }
        case CATEGORY_CONST.FETCH_CATEGORIES_FAIL: {
            return {
                ...state,
                fetchingCats: false,
                snackMsg: action.payload,
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  create categry
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case CATEGORY_CONST.CREATE_CATEGORY_START: {
            return {
                ...state,
                asyncLoader: true,
                snackMsg: null,
            }
        }
        case CATEGORY_CONST.CREATE_CATEGORY_SUCCESS: {
            return {
                ...state,
                asyncLoader: false,
            }
        }
        case CATEGORY_CONST.CREATE_CATEGORY_FAIL: {
            return {
                ...state,
                asyncLoader: false,
                snackMsg: action.payload,
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  delete category
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case CATEGORY_CONST.DELETE_CATEGORY_START: {
            return {
                ...state,
                asyncLoader: true,
                snackMsg: null,
            }
        }
        case CATEGORY_CONST.DELETE_CATEGORY_SUCCESS: {
            return {
                ...state,
                asyncLoader: false,
            }
        }
        case CATEGORY_CONST.DELETE_CATEGORY_FAIL: {
            return {
                ...state,
                asyncLoader: false,
                snackMsg: action.payload,
            }
        }

        default: return state;
    }
}