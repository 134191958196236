import STAFF_CONST from './staff-constants';


let initial_state = {
    staffList: [],
    updatingStaff: false,
    addingStaff: false,
    fetchingStaffList: false,
    suspendingStaff: false,
    deletingStaff: false,
    snackMsg: null
}

function changeSuspendVal(staffList, payload){
    staffList.find((staffMember, i) => {
        if(staffMember._id === payload.susId){
            staffList[i].suspended = payload.susValue;
            return true;
        } else return undefined;
    })
    return staffList;
}

export const staffReducer = (state = initial_state, action) => {
    switch(action.type){
        
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  Unmount staff page
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case STAFF_CONST.UNMOUNT_STAFF_PAGE: {
            return initial_state
        }
    
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  Fetch staff list
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case STAFF_CONST.FETCH_STAFF_LIST_START: {
            return {
                ...state,
                fetchingStaffList: true,
                snackMsg: null,
            }
        }
        case STAFF_CONST.FETCH_STAFF_LIST_SUCCESS: {
            return {
                ...state,
                fetchingStaffList: false,
                staffList: action.payload, // array of staff member
            }
        }
        case STAFF_CONST.FETCH_STAFF_LIST_FAIL: {
            return {
                ...state,
                fetchingStaffList: false,
                snackMsg: action.payload, // message string
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  Add Staff
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case STAFF_CONST.ADD_NEW_STAFF_START: {
            return {
                ...state,
                addingStaff: true,
                snackMsg: null,
            }
        }
        case STAFF_CONST.ADD_NEW_STAFF_SUCCESS: {
            return {
                ...state,
                addingStaff: false,
            }
        }
        case STAFF_CONST.ADD_NEW_STAFF_FAIL: {
            return {
                ...state,
                addingStaff: false,
                snackMsg: action.payload, // message string
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  Update Staff
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case STAFF_CONST.UPDATE_STAFF_START: {
            return {
                ...state,
                updatingStaff: true,
                snackMsg: null,
            }
        }
        case STAFF_CONST.UPDATE_STAFF_SUCCESS: {
            return {
                ...state,
                updatingStaff: false,
            }
        }
        case STAFF_CONST.UPDATE_STAFF_FAIL: {
            return {
                ...state,
                updatingStaff: false,
                snackMsg: action.payload, // message string
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  staff suspension
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case STAFF_CONST.SUSPEND_STAFF_START: {
            return {
                ...state,
                suspendingStaff: true,
                snackMsg: null,
            }
        }
        case STAFF_CONST.SUSPEND_STAFF_SUCCESS: {
            return {
                ...state,
                suspendingStaff: false,
            }
        }
        case STAFF_CONST.SUSPEND_STAFF_FAIL: {
            return {
                ...state,
                suspendingStaff: false,
                snackMsg: action.payload, // message string
            }
        }
        case STAFF_CONST.SET_STAFF_SUSPENSION: {
            return {
                ...state,
                staffList: changeSuspendVal(state.staffList, action.payload),
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  delete staff
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case STAFF_CONST.DELETE_STAFF_START: {
        return {
            ...state,
            deletingStaff: true,
            snackMsg: null,
        }
    }
    case STAFF_CONST.DELETE_STAFF_SUCCESS: {
        return {
            ...state,
            deletingStaff: false,
        }
    }
    case STAFF_CONST.DELETE_STAFF_FAIL: {
        return {
            ...state,
            deletingStaff: false,
            snackMsg: action.payload, // message string
        }
    }


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  snack messages
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case STAFF_CONST.SET_SNACK_MSG: {
            return {
                ...state,
                snackMsg: action.payload,
            }
        }

        
        default: return state;
    }
}