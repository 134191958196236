import React from 'react';
import {Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { DialogImage } from '../components';

import  Utility from '../../../../lib/utility';
import { DialogDismissButton } from '../../../../components/buttons';

const LoginTable = (props) => {
    return (
        <Paper classes = {{root: 'table-container-paper'}}>

            <DialogImage 
                title       = { props.user.fullname }
                subTitle    = { "Login Detail" }
                imageSrc    = { props.user.image }
            />

            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">Browser</TableCell>
                        <TableCell align="center">OS</TableCell>
                        <TableCell align="center">Time</TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            <div style={{ overflow: 'auto', height: '200px' }}>
                <Table style={{tableLayout: 'fixed'}} size="small">
                    <TableBody>
                        { props.loginData.length === 0 &&  
                        <TableRow>
                            <TableCell align="center" colSpan={4} style={{ height: 40 }}>No Login Data Found</TableCell>
                        </TableRow>
                        }
                        { props.loginData.length > 0 && props.loginData.map(( loginDetail, i ) => 
                        <TableRow key = { loginDetail._id } hover>
                            <TableCell align="center">{ i+1 }</TableCell>
                            <TableCell align="center">{ loginDetail.browser }</TableCell>
                            <TableCell align="center">{ loginDetail.os }</TableCell>
                            <TableCell align="center">{ Utility.formatFullDate(loginDetail.time) }</TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <DialogDismissButton onClick = { props.onClickDismissBtn } />

        </Paper>
    )
}

LoginTable.propTypes = {
    loginData: PropTypes.array,
}

LoginTable.defaultProps = {
    loginData: [],
}

export default LoginTable;