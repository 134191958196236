import React from 'react';
import  PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, 
  DialogContentText, Button, Slide } from '@mui/material';

const  Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
})

const ConfirmDialog = (props) => (
  <Dialog
    open                = { props.show }
    onClose             = { props.onCancel }
    TransitionComponent = { Transition }
    style               = {{zIndex: 1000000}}
  >
    <DialogTitle> { props.title } </DialogTitle>
    <DialogContent>
      <DialogContentText> { props.message } </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color   = { "primary" } onClick = { props.onCancel } > 
        { props.cancelText }
      </Button>
      <Button color   = { "secondary" } onClick = { props.onConfirm } > 
        { props.confirmText }
      </Button>
    </DialogActions>
  </Dialog>
)

ConfirmDialog.propTypes = {
    show:       PropTypes.bool,
    title:      PropTypes.string,
    message:    PropTypes.string,
    cancelText: PropTypes.string,
    confirmText:PropTypes.string,
    onCancel:   PropTypes.func,
    onConfirm:  PropTypes.func,
}

ConfirmDialog.defaultProps = {
    show:       false,
    title:      'No Title',
    message:    'No message....',
    cancelText: 'No',
    confirmText:'Yes',
    onCancel:   () => console.log('Not set...'),
    onConfirm:  () => console.log('Not set...'),
}

export default ConfirmDialog;