import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Editor, EditorState, convertToRaw, RichUtils, Modifier, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';

import { SegmentContainer } from '../../../components/container';


var m = {"q":"ق","w":"و","e":"ع","r":"ر","t":"ت","y":"ے","u":"ء","i":"ی","o":"ہ","p":"پ","a":"ا","s":"س","d":"د","f":"ف","g":"گ","h":"ح","j":"ج","k":"ک","l":"ل","z":"ز","x":"ش","c":"چ","v":"ط","b":"ب","n":"ن","m":"م","`":"ً",",":"،",".":"۔","Q":"ْ","W":"ّ","E":"ٰ","R":"ڑ","T":"ٹ","Y":"َ","U":"ئ","I":"ِ","O":"ۃ","P":"ُ","A":"آ","S":"ص","D":"ڈ","G":"غ","H":"ھ","J":"ض","K":"خ","Z":"ذ","X":"ژ","C":"ث","V":"ظ","N":"ں","M":"٘","~":"ٍ","?":"؟","F":"ٔ","L":"ل","B":"ب"};
var urduNumerals = {"0": "۰", "1": "۱", "2": "۲", "3": "۳", "4": "۴", "5": "۵", "6": "۶", "7": "۷", "8": "۸", "9": "۹"};


class Translator extends Component {
    
    state = { 
        last: '',
        editorState: EditorState.createEmpty()
    };

    componentDidMount(){
        
        //console.log(this.refs.input.selectionEnd);
       // window.MakeTextBoxUrduEnabled(this.refs.input);
        // console.log(this.refs.input.id);
        // console.log(window);
        //console.log(key);
    }

    onbeforeinput = (e) => {
        console.log(e);
    }

    onInput = (e) => {
        //console.log(e);
       // this.refs.input.value = "abc";
        let pos = this.refs.input.selectionEnd;
		let s = this.refs.input.value;		
		let isLastPos = (pos==s.length);
        if (this.state.last==s) return;
        
        let S = [];
		for (let x = 0; x < s.length; x++)
		{
			let c = s.charAt(x);
            S.push(m[c]||c);
            this.refs.input.value = S.join('');
            this.setState({last: this.refs.input.value});

            if (!isLastPos) {
                this.refs.input.selectionStart = this.refs.input.selectionEnd = pos;
            }
		}
    }

    onChangeEditor = (editorState) => {
        this.setState({ editorState });
    }

    handleBeforeInput = (c, editorState) => {
        // console.log(c);
        // console.log(this.state.editorState.getCurrentContent().getPlainText());

        const selection = editorState.getSelection();
        const block = editorState.getCurrentContent().getBlockForKey(selection.getAnchorKey());
        
        
        //if (block.getText().startsWith("--")) {
          // Replace '--' with '*'

          const contentWithoutDash = Modifier.replaceText(
              editorState.getCurrentContent(),
              new SelectionState({
                anchorKey: block.getKey(),
                anchorOffset: 0,
                focusKey: block.getKey(),
                focusOffset: selection.getEndOffset()
              }),
              "خ");
          editorState = EditorState.push(
              editorState,
              contentWithoutDash,
              'replace-text'
          );
        //}
        this.setState({editorState});
        return 'handled';

        
        // this.setState({
        //     editorState: (m[c] || c, editorState),
        //   });
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
    
        if (newState) {
          this.onChangeEditor(newState);
          return 'handled';
        }
    
        return 'not-handled';
      }


////////////////////////////////////////////////////////////////////////////////////////
///////////         Main render function
///////////////////////////////////////////////////////////////////////////////////////
    render(){
        return(
            <SegmentContainer pageTitle="Translator">
                {/* { this.state.loadingData && this.renderLoader() }
                { this.state.latestData !== null && this.renderContent() } */}
                
                <textarea ref = { 'input' } onInput = { this.onInput } style={{ fontSize: 28, direction: 'rtl', fontFamily: 'Nastaliq' }} />
                <br />

                <Editor 
                    placeholder = "Type here...."
                    editorState = { this.state.editorState }
                    onChange = { this.onChangeEditor }
                    handleBeforeInput = { this.handleBeforeInput }
                    handleKeyCommand={this.handleKeyCommand.bind(this)}
                />
                
            </SegmentContainer>
        )
    }
}

export default connect(null, null)(Translator);