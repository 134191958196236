import React from 'react';
import { connect } from 'react-redux';
import {  CircularProgress, Grid  } from '@mui/material';
import PropTypes from 'prop-types';

import { setSnackMsgs, suspendStaff, deleteStaff } from '../../../../redux/staff/staff-actions';
import { UserCard } from '../../../../components/card';
import { ConfirmDialog, Snackbar } from '../../../../components/dialog';
import Utility  from '../../../../lib/utility';
import { endPoints }  from '../../../../lib/values';

class EmployeeList extends React.Component {

    state = {
        confirmAction: null,
        userForAction: null,
        delStaff: null,
    }

    onCloseInputDialog = () => {
        this.setState({ confirmAction: null, userForAction: null });
    }

    onConfirmAction = () => {
        
        const { confirmAction, userForAction } = this.state;
        this.setState({confirmAction: null});

        if(confirmAction === 'Suspend'){
            this.props.suspendStaff(`${endPoints.employees.suspendEmployee}/${userForAction._id}`, Utility.httpRequest("POST"), (err, success) => {
                if(!err){
                    this.props.setSnackMsgs(success.message)
                    this.props.refreshList()
                }
            })
        } else if(confirmAction === 'Un-suspend'){
            this.props.suspendStaff(`${endPoints.employees.unsuspendEmployee}/${userForAction._id}`, Utility.httpRequest("POST"), (err, success) => {
                if(!err){
                    this.props.setSnackMsgs(success.message)
                    this.props.refreshList()
                }
            })
        
        } else if(confirmAction === 'Delete'){
            this.props.deleteStaff(`${endPoints.employees.deleteEmployee}/${userForAction._id}`, Utility.httpRequest("DELETE"), (err, success) => {
                if(!err){
                    this.props.setSnackMsgs(success.message)
                    this.props.refreshList()
                }
            })
        }
    }

    onClickMenuItem = (action, userForAction) => {
        switch(action){
            case 'update':      return this.props.onClickUpdateStaff(userForAction)
            case 'loginDetail': return this.props.onClickLoginDetailBtn(userForAction)
            case 'delete':      return this.setState({confirmAction: 'Delete',      userForAction})
            case 'unsuspend':   return this.setState({confirmAction: 'Un-suspend',  userForAction})
            case 'suspend':     return this.setState({confirmAction: 'Suspend',     userForAction})
        }
    }

    showInProgress = (user) => {
        const { staffReducer } = this.props;
        const { userForAction } = this.state;

        if(staffReducer.deletingStaff && user._id === userForAction._id){
            return true
        } else if(staffReducer.suspendingStaff && user._id === userForAction._id){
            return true;
        }
        return false;
    }


    render(){
        const { staffReducer } = this.props;
        const { confirmAction, userForAction } = this.state;
    return (
        <>

            <ConfirmDialog 
                show        = { Boolean(confirmAction) } 
                title       = "Confirmation"
                message     = { `Are you sure you want to ${confirmAction} ${userForAction?.fullname}?` }
                onCancel    = { this.onCloseInputDialog }
                onConfirm   = { this.onConfirmAction }
            />

            <Snackbar 
                open        = { Boolean(staffReducer.snackMsg) }
                message     = { staffReducer.snackMsg }
                onClose     = { () => this.props.setSnackMsgs(null) }
            />

                <Grid container spacing={6}>
                        
                        { staffReducer.fetchingStaffList
                        ? 
                        <div style = {{ display: 'flex', padding: 50, justifyContent: 'center', flex: 1 }}>
                            <CircularProgress />
                        </div>
                        : staffReducer.staffList.length >  0 && staffReducer.staffList.map((user) => 
                            <Grid item xs = { 12 } sm = { 6 } md = { 4 } lg = { 3 } key = { user._id }>
                                <UserCard 
                                    user            = { user }
                                    inProgress      = { this.showInProgress(user) }
                                    onClickMenuItem = { (action) => this.onClickMenuItem(action, user) }
                                />
                            </Grid>
                        )}
                    </Grid>
        </>
    )
    }
}

EmployeeList.propTypes = {
    onClickUpdateStaff :    PropTypes.func,
    onClickLoginDetailBtn:  PropTypes.func,

}


function mapStateToProps(state){
    return {
        staffReducer: state.staffReducer
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsgs:       (msg)           => dispatch(setSnackMsgs(msg)),
        suspendStaff:       (url, body, cb) => dispatch(suspendStaff(url, body, cb)),
        deleteStaff:        (url, body, cb) => dispatch(deleteStaff(url, body, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);