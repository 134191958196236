import React, { Fragment } from 'react';
import { Button, IconButton, Avatar, Hidden, Typography, Tooltip } from '@mui/material';
import { MdPowerSettingsNew } from 'react-icons/md';
import PropTypes from 'prop-types';

import '../styles.css';

const UserButton = (props) => {
    return (
        <Fragment>

            { /** User button for extra small screen */ }
            <Hidden xsDown>
                <Tooltip title="Profile">
                    <Button className="avatar-container" onClick = { props.onClicUserBtn }>
                        <Avatar 
                            alt = { props.userBtnText } 
                            className="avatar" 
                            src= { props.userImage }
                        /> 
                        <Typography style={{color: '#fff'}}> { props.userBtnText } </Typography>
                    </Button>
                </Tooltip>
            </Hidden>

            { /** User button for small and up screen */ }
            <Hidden smUp>
                <IconButton color="inherit" aria-label="User" onClick = { props.onClicUserBtn }>
                    <Avatar 
                        alt = { props.userBtnText } 
                        className="avatar" 
                        src= { props.userImage } 
                    />
                </IconButton>
            </Hidden>
        </Fragment>
    )
}

const LogoutButton = (props) => {
    return (
        <Tooltip title="Logout">
            <IconButton color="inherit" aria-label="Logout" onClick = { props.onClickLogoutBtn }>
                <MdPowerSettingsNew />
            </IconButton>
        </Tooltip>
    )
}
/************************************************************************************************
 *   Root Component
 ************************************************************************************************/
const RightButtons = (props) => {
    return (
        <div>
            <UserButton { ...props } />
            <LogoutButton  { ...props } />
        </div>
    )
}

RightButtons.propTypes = {
    onClicUserBtn:      PropTypes.func,
    userBtnText:        PropTypes.string,
    userImage:          PropTypes.string,
    onClickLogoutBtn:   PropTypes.func,
}

RightButtons.defaultProps = {
    userBtnText:        'No User',
    userImage:          "/assets/images/user.png",
    onClickLogoutBtn:   () => console.log('Not configured'),
}

export default RightButtons;