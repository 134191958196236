import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrimaryButton } from '../../components/buttons';
import { authorizing, setSnackMsg } from '../../redux/auth/auth-actions';
import { PrimaryInput } from '../../components/input';
import { Container } from '../../components/container';
import { Snackbar } from '../../components/dialog';
import Utility from '../../lib/utility';
import { storageKeys, endPoints } from '../../lib/values';

class Auth extends Component {

    state = {
        inputs: {},
    }

    componentWillMount(){
        console.log(process.env.REACT_APP_API_HOST);
        this.setState({ 
            inputs: { 
                email: localStorage.getItem(storageKeys.email) || '' 
            }
        })
    }

    onLogin = () => {
         // login async call
        this.props.login(endPoints.auth.login, Utility.httpRequest("POST" , this.state.inputs), (err, success) => {
            if(err === null){
                sessionStorage.setItem(storageKeys.appToken, success.access_token);
                localStorage.setItem(storageKeys.email, success.email);
                window.location = '/app';
            }
        })
    }


    render(){
        const { authReducer } = this.props;
        const { inputs } = this.state;
        return (
            <>
            <Container authContainer = {false}>
                { /** Snackbar for login messages */ }
                <Snackbar 
                    message = { authReducer.snackMsg } 
                    open    = { Boolean(authReducer.snackMsg) }
                    onClose = { () => this.props.setSnackMsg(null) } 
                />
                <div className="auth-box">

                    <div className="auth-box-header">
                        <img 
                            src       = { '/assets/images/bazi.png' } 
                            alt       = "Bazi"
                            className = "logo-img"
                        />
                    </div>

                    <PrimaryInput 
                        inputLabel          = { "Email" }
                        inputValue          = { inputs.email || '' }
                        formControlClasses  = { "light-input-container" } 
                        onChangeInput       = { e => this.setState({ inputs: { ...inputs, email: e.target.value } }) }
                    />
                    
                    <PrimaryInput
                        inputLabel          = { "Password" }
                        inputValue          = { inputs.password || '' }
                        inputType           = { "password" }
                        formControlClasses  = { "light-input-container" } 
                        onChangeInput       = { e => this.setState({ inputs: { ...inputs, password: e.target.value } }) }
                    />
                    
                
                    <div className="right-btn-flex">
                        <PrimaryButton 
                            buttonText      = "Login"
                            buttonVariant   = "contained"
                            onClick         = { this.onLogin }
                            inProgress      = { authReducer.authorizing }
                            buttonDisabled  = { authReducer.authorizing }
                        />
                    </div>

                </div>
            </Container>
            </>
        )
    }
}

function mapStateToProps(state){
    return { authReducer: state.authReducer }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:    (msg)                   => dispatch(setSnackMsg(msg)),
        login:          (url, body, callback)   => dispatch(authorizing(url, body, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);