import MEDIA_CONST from './media-constants';
import { perPage } from '../../lib/values';

var initial_state = {
    selectedTab: 1,

    imagesFetching:    false,
    imagesRefreshing:  false,

    iconsFetching:     false,
    iconsRefreshing:   false,

    fileDeleting:      false,
    movingFile:        false,

    hasMoreImages:     true,
    hasMoreIcons:      true,

    imagesList:        [],
    iconsList:         [],

    uploadingPercent:  null,
    selectedFile:      null,
    snackMsg:          null,
    fileBase64:        null,
}

function remArrayItem(array, itemId){
    array.find((arrayItem, i) => {
        if(arrayItem._id === itemId){
            array.splice(i, 1);
            return true;
        } else return undefined;
    });
    return array;
}

export const mediaReducer = (state = initial_state, action) => {
    switch(action.type){

        case MEDIA_CONST.SET_MM_TAB: {
            return {
                ...state,
                selectedTab: action.payload, // tab value (1, 2, 3) => 1 = uploadView, 2 = Images List, 3 = Icons List,
                imagesList: [],
                iconsList: [],
                hasMoreImages: true,
                hasMoreIcons: true,
                selectedFile: null
            }
        }

        case MEDIA_CONST.CLEAR_MEDIA_MANAGER: {
            return {
                ...state,
                selectedTab: 1,
                imagesList: [],
                iconsList: [],
                hasMoreImages: true,
                hasMoreIcons: true,
                selectedFile: null
            }          
        }
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  media msgs
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case MEDIA_CONST.SET_SNACK_MSG: {
            return {
                ...state,
                snackMsg: action.payload
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  base64
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case MEDIA_CONST.SET_FILE_BASE64: {
            return {
                ...state,
                fileBase64: action.payload
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  fetch images
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case MEDIA_CONST.FETCH_IMAGES_START: {
            return {
                ...state,
                imagesFetching: true,
                snackMsg: null
            }
        }
        case MEDIA_CONST.FETCH_IMAGES_SUCCESS: {
            return {
                ...state,
                imagesFetching: false,
                imagesList: state.imagesList.concat(action.payload),
                hasMoreImages: action.payload.length < perPage.mmFiles ? false : true
            }
        }

        case MEDIA_CONST.FETCH_IMAGES_FAIL: {
            return {
                ...state,
                imagesFetching: false,
                snackMsg: action.payload
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  refresh images
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case MEDIA_CONST.REFRESH_IMAGES_START: {
            return {
                ...state,
                imagesRefreshing: true,
                snackMsg: null
            }
        }
        case MEDIA_CONST.REFRESH_IMAGES_SUCCESS: {
            return {
                ...state,
                imagesRefreshing: false,
                imagesList: action.payload,
                selectedFile: null,
                hasMoreImages: action.payload.length < perPage.mmFiles ? false : true
            }
        }
        case MEDIA_CONST.REFRESH_IMAGES_FAIL: {
            return {
                ...state,
                imagesRefreshing: false,
                snackMsg: action.payload
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  fetch icons
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        case MEDIA_CONST.FETCH_ICONS_START: {
            return {
                ...state,
                iconsFetching: true,
                snackMsg: null,
            }
        }
        case MEDIA_CONST.FETCH_ICONS_SUCCESS: {
            return {
                ...state,
                iconsFetching: false,
                iconsList: state.iconsList.concat(action.payload),
                hasMoreIcons: action.payload.length < perPage.mmFiles ? false : true
            }
        }
        case MEDIA_CONST.FETCH_ICONS_FAIL: {
            return {
                ...state,
                iconsFetching: false,
                snackMsg: action.payload
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  refresh icons
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case MEDIA_CONST.REFRESH_ICONS_START: {
            return {
                ...state,
                iconsRefreshing: true,
                snackMsg: null
            }
        }
        case MEDIA_CONST.REFRESH_ICONS_SUCCESS: {
            return {
                ...state,
                iconsRefreshing: false,
                iconsList: action.payload,
                selectedFile: null,
                hasMoreIcons: action.payload.length < perPage.mmFiles ? false : true
            }
        }
        case MEDIA_CONST.REFRESH_ICONS_FAIL: {
            return {
                ...state,
                iconsRefreshing: false,
                snackMsg: action.payload
            }
        }
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  select file
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case MEDIA_CONST.SELECT_FILE: {
            return {
                ...state,
                selectedFile: action.payload, // selected media manager file
            }
        }
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  delete file
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case MEDIA_CONST.DELETE_FILE_START: {
            return {
                ...state,
                fileDeleting: true,
                snackMsg: null
            }
        }

        case MEDIA_CONST.DELETE_FILE_SUCCESS: {
            return {
                ...state,
                fileDeleting: false,
                imagesList: state.selectedTab !== 2 ? state.imagesList :  remArrayItem(state.imagesList, state.selectedFile._id),
                iconsList:  state.selectedTab !== 3 ? state.iconsList  :  remArrayItem(state.iconsList, state.selectedFile._id),
                selectedFile: null
            }
        }

        case MEDIA_CONST.DELETE_FILE_FAIL: {
            return {
                ...state,
                fileDeleting: false,
                snackMsg: action.payload
            }
        }
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  uploding file
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case MEDIA_CONST.SET_UPLOAD_PROGRESS: {
            return {
                ...state,
                uploadingPercent: action.payload // int percent value
            }
        }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////  move file
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        case MEDIA_CONST.MOVE_FILE_START: {
            return {
                ...state,
                movingFile: true,
            }
        }
        case MEDIA_CONST.MOVE_FILE_SUCCESS: {
            return {
                ...state,
                movingFile: false,
                imagesList: state.selectedTab !== 2 ? state.imagesList :  remArrayItem(state.imagesList, state.selectedFile._id),
                iconsList:  state.selectedTab !== 3 ? state.iconsList  :  remArrayItem(state.iconsList, state.selectedFile._id),
                selectedFile: null
            }
        }
        case MEDIA_CONST.MOVE_FILE_FAIL: {
            return {
                ...state,
                movingFile: false,
                snackMsg: action.payload
            }
        }

        default: return state;
    }
}