import React from 'react';
import { Tooltip, IconButton, CircularProgress, Tabs, Tab } from '@mui/material';
import { MdClose, MdRefresh } from 'react-icons/md';
import PropTypes from 'prop-types';
import '../styles.css';


////////////////////////////////////////////////////////////////////////////////////////////////////
//     Header right side buttons
///////////////////////////////////////////////////////////////////////////////////////////////////
const HeaderButtons = (props) => {
    return (
        <div className = "header-action-btns">
            { (props.selectedTab  === 2 || props.selectedTab  === 3) && 
                <Tooltip title={ `Refresh ${props.selectedTab === 2 ? 'Images' : 'Icons'}` } > 
                    <IconButton onClick = {() => props.onRefreshBtnClick() }> 
                        { props.refreshBtnIndicator 
                            ? <CircularProgress size={24} /> 
                            : <MdRefresh  color="#36b0df"/> 
                        } 
                    </IconButton> 
                </Tooltip> 
            }
            { !props.hideCloseBtn && 
            <IconButton onClick = { props.onCloseMM } >
                <MdClose />
            </IconButton>
            }
        </div> 
    )
}


////////////////////////////////////////////////////////////////////////////////////////////////////
//     Tabs Component
///////////////////////////////////////////////////////////////////////////////////////////////////
const HeaderTabs = (props) => {
    return (
        <Tabs 
            value           =   { props.selectedTab } 
            indicatorColor  =   { "primary" } 
            onChange        =   { props.onChangeTab }>
            <Tab label = "Upload"   value = {1} />
            <Tab label = "Images"   value = {2} />
            <Tab label = "Icons"    value = {3} />
        </Tabs>
    )
}


////////////////////////////////////////////////////////////////////////////////////////////////////
//     Default exported Component
///////////////////////////////////////////////////////////////////////////////////////////////////
export const Header = (props) => {
    return (
        <div className = "file-uploader-header">
            <h2 className = "file-uploader-title"> Media Manager </h2>
            <HeaderButtons {...props} />
            <HeaderTabs {...props} />
        </div>
    )
}

Header.propTypes = {
    seletedTab:             PropTypes.number,
    onRefreshBtnClick:      PropTypes.func,
    refreshBtnIndicator:    PropTypes.bool,
    onChangeTab:            PropTypes.func
}