import React, { Fragment } from 'react';
import { Hidden, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import '../styles.css';
import { PrimaryButton } from '../../../../components/buttons';
import { ErrorText } from '../../../../components/messages';
import Utility from '../../../../lib/utility';

////////////////////////////////////////////////////////////////////////////////////////////////////
//     Footer file info
///////////////////////////////////////////////////////////////////////////////////////////////////
const FooterFileInfo = (props) => {
    return (
        <Hidden smDown>
            <div className = "selected-file-info">
                <div className="library-file-info-wrapper">
                    <Typography className = "library-file-info-head"> 
                        Upload On: 
                    </Typography>
                    <Typography className = "library-file-info-text"> 
                        { Utility.formatFullDate(props.uploadTime)}
                    </Typography> 
                </div>
                <div className="library-file-info-wrapper">
                    <Typography className="library-file-info-head">
                        Upload By: 
                    </Typography>
                    <Typography className="library-file-info-text"> 
                        { props.uploadByUserName }
                    </Typography>
                </div>
            </div>
        </Hidden>
    )
}


////////////////////////////////////////////////////////////////////////////////////////////////////
//    Footer right control buttons
///////////////////////////////////////////////////////////////////////////////////////////////////
const FooterControlBtns = (props) => {
    return (
        <div className="library-btn-container">
            
            <PrimaryButton 
                buttonText      = "Move"
                buttonColor     = "primary"
                buttonSize      = "small"
                inProgress      = { props.moveIndicator }
                buttonDisabled  = { props.moveIndicator }
                onClick         = { props.onMoveClick }
                style           = {{ marginRight: 10 }}
            />    
            <PrimaryButton 
                buttonText      = "Delete"
                buttonColor     = "secondary"
                inProgress      = { props.deleteIndicator }
                buttonDisabled  = { props.deleteIndicator }
                onClick         = { props.onDeleteClick }
                style           = {{ marginRight: 10 }}
            />      
            <PrimaryButton 
                buttonText      = "View"
                style           = {{ marginRight: 10 }}
                href            = { props.viewableFileUrl }
            />
            { !props.hideInsertButton && 
                <PrimaryButton 
                    buttonText      = "Insert"
                    buttonVariant   = "contained"
                    buttonDisabled  = { props.disabledInsertBtn }
                    style           = {{ marginRight: 10 }}
                    onClick         = { props.onClickInsertBtn }
                />
            }
        </div>
    )
}


////////////////////////////////////////////////////////////////////////////////////////////////////
//     Default exported Component
///////////////////////////////////////////////////////////////////////////////////////////////////
export const Footer = (props) => {
    return (
        
        <div className = "file-uploader-footer">
            <Fragment>
                { props.snackMsg ? 
                <div className="footer-snack-err">
                    <ErrorText text = { props.snackMsg } onClose = { props.onCloseSnackMsg } />
                </div>
                :
                props.isFileSelected && 
                <div className="footer-file-info">
                    <FooterFileInfo { ...props }/>
                    <FooterControlBtns { ...props } />
                </div> 
            }
            </Fragment>
        </div>
    )
}

Footer.propTypes = {
    uploadTime:         PropTypes.string,
    uploadByUserName:   PropTypes.string,
    deleteIndicator:    PropTypes.bool,
    onDeleteClick:      PropTypes.func,
    viewableFileUrl:    PropTypes.string,
    disabledInsertBtn:  PropTypes.bool,
    isFileSelected:     PropTypes.bool,
    onClickInsertBtn:   PropTypes.func,
}