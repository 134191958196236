import React, { Component } from 'react';
import { CircularProgress } from '@mui/material';
import { connect } from 'react-redux';

import { Container } from '../../components/container';
import {  Profile, Dashboard, Employees,
    /*  ManageMedia, CategoryView,  Translator */ } from '../../views/content-view';
// import { NewsEditor, NewsList } from '../../views/content-view/news';
import { AppBar } from '../../views/full-view';
import { logout, fetchProfile } from '../../redux/auth/auth-actions';
import { setContentView } from '../../redux/dom/dom-actions';
import { cViews, endPoints } from '../../lib/values';
import Utility from '../../lib/utility';
import { SessionMsg } from '../index';
import { Sidebar } from './segments';


class App extends Component {

    componentDidMount(){
        const { authReducer } = this.props;
        !authReducer.user._id && this.props.fetchProfile(endPoints.auth.myProfile, Utility.httpRequest('GET'), (err, user) => {
            // do something here
        })
    }

    onLogout = () => {
        this.props.setContentView(cViews.dashboard);
        this.props.logout();
        window.location = '/auth';
    }

    renderContentView = () => {
        switch(this.props.domReducer.contentView){
            //case cViews.dashboard    :   return <Dashboard />
            case cViews.profile      :   return <Profile />
            // case cViews.categoryView :   return <CategoryView />
            // case cViews.mediaView    :   return <ManageMedia showAsSegment = { true } />
            case cViews.employees       :   return <Employees />
            // case cViews.newsEditor   :   return <NewsEditor />
            // case cViews.newsView     :   return <NewsList />
            default                  :   return <Profile />
        }
    }

    renderApp = () => { 
        const { authReducer } = this.props;
        return authReducer.user._id && !authReducer.fetchingProfile &&
        <>
            <AppBar  onLogout = { this.onLogout } />
            <Sidebar />
            <div style={{ flex: 1, marginTop: 72, minHeight: 'calc(100vh - 72px)' }}>
            { this.renderContentView() }
            </div>
        </>
    }

    renderProfileLoader = () => {
        const { authReducer } = this.props;
        return authReducer.fetchingProfile && <CircularProgress />
    }

    renderSessionMsg = () => {
        const { authReducer } = this.props;
        return authReducer.sessionData.msg && <SessionMsg />
    }

    render(){
        return (
            <Container>
                { this.renderProfileLoader() }
                { this.renderApp() }
                { this.renderSessionMsg() }
            </Container>
        )
    }
}

function mapStateTopProps(state){
    return {
        domReducer: state.dom,
        authReducer: state.authReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        logout:         ()              => dispatch(logout()),
        setContentView: (view)          => dispatch(setContentView(view)),
        fetchProfile:   (url, body, cb) => dispatch(fetchProfile(url, body, cb))
    }
}

export default connect(mapStateTopProps, mapDispatchToProps)(App);