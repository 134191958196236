export default {
    UNMOUNT_STAFF_PAGE:                 'UNMOUNT_STAFF_PAGE',
    SET_SNACK_MSG:                      'SET_SNACK_MSG',

    FETCH_STAFF_LIST_START:             'FETCH_STAFF_LIST_START',
    FETCH_STAFF_LIST_SUCCESS:           'FETCH_STAFF_LIST_SUCCESS',
    FETCH_STAFF_LIST_FAIL:              'FETCH_STAFF_LIST_FAIL',

    ADD_NEW_STAFF_START:                'ADD_NEW_STAFF_START',
    ADD_NEW_STAFF_SUCCESS:              'ADD_NEW_STAFF_SUCCESS',
    ADD_NEW_STAFF_FAIL:                 'ADD_NEW_STAFF_FAIL',

    UPDATE_STAFF_START:                 'UPDATE_STAFF_START',
    UPDATE_STAFF_SUCCESS:               'UPDATE_STAFF_SUCCESS',
    UPDATE_STAFF_FAIL:                  'UPDATE_STAFF_FAIL',

    SUSPEND_STAFF_START:                'SUSPEND_STAFF_START',
    SUSPEND_STAFF_SUCCESS:              'SUSPEND_STAFF_SUCCESS',
    SUSPEND_STAFF_FAIL:                 'SUSPEND_STAFF_FAIL',
    SET_STAFF_SUSPENSION:               'SET_STAFF_SUSPENSION',

    DELETE_STAFF_START:                'DELETE_STAFF_START',
    DELETE_STAFF_SUCCESS:              'DELETE_STAFF_SUCCESS',
    DELETE_STAFF_FAIL:                 'DELETE_STAFF_FAIL',
}