import React from 'react';
import PropTypes from 'prop-types';
import Utility from '../../../../lib/utility';

const DialogImage = (props) => {
    return (
        <div className="user-img-wrapper" style={{flexDirection: 'column', padding: 15}}>
            <div className="image-container">
                <img 
                    alt         = "user thumbnail" 
                    src         = { props.imageSrc }
                    className   = "user-img"  
                />
            </div> 
            { props.title && <h4 className="primary-bold" style={{padding: 0}}>{ props.title }</h4> }
            { props.subTitle  && <h5 className="primary-bold" style={{padding: 0, margin: 0}}>{ props.subTitle }</h5> }
        </div>
    )
}

DialogImage.defaultProps = {
    imageSrc:   PropTypes.string,
    title:      PropTypes.string,
    subTitle:   PropTypes.string,
}
DialogImage.defaultProps = {
    imageSrc:   '/assets/images/user.png',
}


export default DialogImage;