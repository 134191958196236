import React, { Component } from 'react';
import { Snackbar, IconButton } from '@mui/material';
import { MdClose } from 'react-icons/md';

export default class Snckbar extends Component {
    
    render(){
        return (
            <Snackbar 
                anchorOrigin      = { { vertical: this.props.vertical || 'bottom', horizontal: this.props.horizontal || 'center' }}
                autoHideDuration  = { this.props.autoHideDuration || 3000 }
                message           = { <span> { this.props.message } </span>}
                open              = { this.props.open || false }
                onClose           = { this.props.onClose }
                style             = { { marginBottom: 20 } }
                action={[
                    <IconButton
                      key       = "close"
                      aria-label= "Close"
                      color     = "inherit"
                      onClick   = { this.props.onClose  }
                    >
                      <MdClose />
                    </IconButton>,
                  ]}
            />
        )
    }
    
}