import React from 'react';
import  PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import './styles.css';

const CustomDialog = (props) => {
    return (
        <Dialog
            open        =   { props.show      }
            onClose     =   { props.onClose   }
            maxWidth    =   { props.maxWidth  }
            fullWidth   =   { props.fullWidth }
            >
            { props.children }
        </Dialog>
    )
}

CustomDialog.propTypes = {
    show:       PropTypes.bool,
    onClose:    PropTypes.func,
    maxWidth:   PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
    fullWidth:  PropTypes.bool,
    dialogPaperFullHeight: PropTypes.bool,
}
CustomDialog.defaultProps = {
    maxWidth:   'sm',
    show:       false,
    fullWidth:  false,
    onClose:    () => alert('Not configured'),
    dialogPaperFullHeight: false
}

export default CustomDialog;