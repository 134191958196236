import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton } from './index';
import './styles.css';

const DialogDismissButton = (props) => {
    return (
        <div className="dialog-dismiss-container">
            <PrimaryButton  
                buttonText      = "Dismiss"
                buttonVariant   = "text"
                buttonSize      = "small"
                onClick         = { props.onClick }
            />
        </div>
    )
}

DialogDismissButton.propTypes = {
    onClick: PropTypes.func,

}

export default DialogDismissButton;