import { combineReducers } from 'redux';

import { authReducer } from './auth/auth-reducer';
import { dom } from './dom/dom-reducer';
import { mediaReducer } from './media/media-reducer';
import { categoryReducer } from './category/category-reducer';
import { staffReducer } from './staff/staff-reducer';
import { newsReducer } from './news/news-reducer';


const appReducer = combineReducers({
    authReducer,
    dom,
    mediaReducer,
    categoryReducer,
    staffReducer,
    newsReducer,
})

// const rootReducers = (state, action) => {
//     if (action.type === 'LOGOUT') {
//         state = undefined
//     }
//     return appReducer(state, action);
// }

export default appReducer;