import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton } from '../buttons';


const NoDataMessage = (props) => {
    return (
        <div style = {{ maxWidth: 500, margin: 'auto' }}>
            <img 
                src =  { '/assets/images/nodata.png' } 
                style= {{ objectFit: 'contain', width: '100%' }} 
                alt = " No data "
            />
            { props.showRefreshBtn && 
                <PrimaryButton 
                    buttonFullWidth = {true}
                    buttonText = "Click here to refresh"
                    buttonVariant = "text"
                    onClick = { props.onClickRefreshBtn }
                />
            }
        </div>
        
    )
}

NoDataMessage.propTypes = {
    showRefreshBtn: PropTypes.bool,
    onClickRefreshBtn: PropTypes.func,
    refreshBtnText: PropTypes.string
}

NoDataMessage.defaultProps = {
    showRefreshBtn: true,
    refreshBtnText: 'Click here to refresh'
}

export default NoDataMessage;