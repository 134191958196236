import React, { Component } from 'react';
import { connect } from 'react-redux';

import Utility from '../../../../lib/utility';
import { setTab, setSnackMsg, setUploadProgress, setFileBase64 } from '../../../../redux/media/media-actions';
import { UploadBtn } from '../components/upload-btn';
import { UploadingImg } from '../components/uploading-img';
import '../styles.css';
import { endPoints } from '../../../../lib/values';

class Upload extends Component {

    state = {
        uploadAsIcon: false,
    }    

    onChangeUploadAs = (value) => {
        this.setState({uploadAsIcon: value});
    }

    onUploadFileProgress = (progressEvent) => {
        this.props.setUploadProgress(Math.ceil(progressEvent.loaded / progressEvent.total * 100));
    }

    onUploadFileComplete = (err, uploadedFile) => {
        this.props.setFileBase64(null);
        this.props.setUploadProgress(null);
        
        if(err){
            this.props.setSnackMsg(err);
        } else if (!uploadedFile.uploadAsIcon){
            this.props.setTab(2)
        } else if (uploadedFile.uploadAsIcon){
            this.props.setTab(3)
        }
    }

    onChangeFilePicker = (files) => {
        const selectedFile = files[0];
        
        const allowedExt = [ 'jpg', 'jpeg', 'png', 'gif'];
        let fileErr = null;
        
        if(selectedFile){
            const fileExt = selectedFile.name.split('.').pop().toLowerCase();
            if(allowedExt.indexOf(fileExt) === -1){
                fileErr = "Only jpeg, jpg, png and gif files are alloweded";
            } else if((selectedFile.size / (1024 * 1024)) > 2){
                fileErr = "Maximum upload file size is 2 MB";
            }

            if(fileErr){
                this.props.setSnackMsg(fileErr);
            } else {
                Utility.imageDimension(selectedFile, (err, outputImg) => {
                    if(err){
                        this.props.setSnackMsg(err);
                    } else {
                        let fileReader = new FileReader();
                        fileReader.readAsDataURL(selectedFile);
                        fileReader.onload  = (e) => {
                            // base64 is used to preview image in uploder tab
                            this.props.setFileBase64(e.target.result);
                            
                            // create form and upload data
                            const fd = new FormData();
                            const dimension = outputImg.width + 'x' + outputImg.height;
                            fd.append('file', selectedFile);
                            fd.append('uploadAs', this.state.uploadAsIcon ? 'icons' : 'images');
                            fd.append('dimension', dimension);
                            Utility.uploadFile(endPoints.media.uploadFile, fd, this.onUploadFileProgress, this.onUploadFileComplete)
                        } // end of read base64 and uploading
                    }
                }) // end of checking dimension
            } // end of else if no error
        }
    }

    render(){
        return (
            <div className="upload-btn-container">
                { this.props.mediaReducer.uploadingPercent && !this.props.mediaReducer.error
                ?
                <UploadingImg 
                    imgSource       = { this.props.mediaReducer.fileBase64 }
                    progressPercent = {  this.props.mediaReducer.uploadingPercent }
                />
                : 
                <UploadBtn 
                    onChangeFilePicker  = { (files) => this.onChangeFilePicker(files) }
                    onChangeUploadAsBtn = {(value) => this.onChangeUploadAs(value) }
                />
                }
            </div>
        )
    }
}

function mapStatetoProps(state){
    return {
        mediaReducer: state.mediaReducer
    }
}

function mapDispatchToProps(dispatch){
    return {
        setTab:              (tab)           => dispatch(setTab(tab)),
        setSnackMsg:         (msg)           => dispatch(setSnackMsg(msg)),
        setUploadProgress:   (progress)      => dispatch(setUploadProgress(progress)),
        setFileBase64:       (base64)        => dispatch(setFileBase64(base64)),
    }
}

export default connect(mapStatetoProps, mapDispatchToProps)(Upload);