import DOM_CONST from './dom-constants';

var initial_state = {
    contentView: 'dashboard',
    visibleXsDrawer: false,
}

export const dom = (state = initial_state, action) => {
    switch(action.type){

        case DOM_CONST.SET_CONTENT_VIEW: {
            return {
                ...state,
                contentView: action.payload
            }
        }

        case DOM_CONST.SET_XS_DRAWER_VISIBLE: {
            return {
                ...state,
                visibleXsDrawer: action.payload
            }
        }
        
        default: return state;
    }
}