import React, { Component } from 'react';
import { IconButton, CircularProgress, Switch, FormControlLabel, Collapse } from '@mui/material';
import { connect } from 'react-redux';
import { MdPhotoCamera } from 'react-icons/md';

import { PrimaryButton } from '../../../components/buttons';
import { PrimaryInput } from '../../../components/input';
import { Snackbar } from '../../../components/dialog';
import { setUserImg, uploadUserImg, updateProfile, setLoginUser, setSnackMsg } from '../../../redux/auth/auth-actions';
import './styles.css';
import Utility from '../../../lib/utility';
import { endPoints, reqMethods } from '../../../lib/values';

class Profile extends Component {

    constructor(props){
        super(props);
        this.state = {
            showPassArea: false,
            user: {fullname: props.authReducer.user.fullname},
            inputs: {fullname: props.authReducer.user.fullname},
        }
    }
    
    onChangeImage = (e) => {
        const file = this.photoChooser.files[0];
        let fd = new FormData();
        fd.append('file', file);
        this.props.uploadUserImg(endPoints.auth.updateUserImg, fd, this.onCompleteUpload);
    } 

    onCompleteUpload = (err, updatedUser) => {
        if(updatedUser){
            this.props.setUserImg(updatedUser.image + '?' + Date.now());
        }
    }

    onChangeText = (e) => {
        this.setState({user: { ...this.state.user, [e.target.name]: e.target.value } }, () => console.log(this.state.user));
    }

    onUpdateProfile = () => {
        const { user, showPassArea } = this.state;
        let reqBody = {
            fullname:           user.fullname,
            current_password:   showPassArea === false ? undefined : user.current_password,
            new_password:       showPassArea === false ? undefined : user.new_password,
        }
        this.props.updateProfile(endPoints.auth.updateProfile, Utility.httpRequest(reqMethods.post, reqBody), (err, success) => {
            if(success) {
                this.props.setLoginUser(success);
                this.props.setSnackMsg('Profile has been updated');
            }
        })
    
    }

    render(){
        const { authReducer } = this.props;
        const { inputs } = this.state;
        return(
            <>
                <Snackbar 
                    open    = { Boolean(authReducer.snackMsg) }
                    message = { authReducer.snackMsg }
                    onClose = { () => this.props.setSnackMsg(null) }
                />
                    <div className="user-img-wrapper">
                        <div className="image-container">
                            {!authReducer.userImgUploading &&  <img className="user-img" alt="user thumbnail" src= { authReducer.user.image  || '/assets/images/user.png' } /> }
                            <div className="img-btn">
                            { !authReducer.userImgUploading && 
                            <IconButton style={{ background: '#aa1d43' }} onClick = {() => this.photoChooser.click() } >
                                <MdPhotoCamera size={26} color = "#fff" />
                            </IconButton>
                            }
                            <input type="file" style={{display: 'none'}} accept="image/*" onChange = { this.onChangeImage } ref = { ref => this.photoChooser = ref }/>
                            </div>
                            { authReducer.userImgUploading && 
                            <div className="uploader">
                                <CircularProgress />
                            </div>
                            }
                        </div>
                    </div>
                    <div className="pr-input-wrapper">
                    <PrimaryInput
                        inputLabel          = { "Fullname" }
                        inputValue          = { inputs.fullname || '' }
                        inputType           = { "text" }
                        formControlClasses  = { "light-input-container" } 
                        onChangeInput       = { e => this.setState({ inputs: { ...inputs, fullname: e.target.value } }) }
                    />

                    <div className="t-creator-input-wrapper">
                        <FormControlLabel
                            control = {
                            <Switch
                                color    = "primary"
                                checked  = { this.state.showPassArea }
                                onChange = {(e, val) => this.setState({showPassArea: val})}
                            />
                            }
                            label ="Change Password"
                            style = {{color: '#888'}}
                        />
                    </div>
                        
                    <Collapse 
                        in = { this.state.showPassArea } >
                            <PrimaryInput
                                inputLabel          = { "Current Password" }
                                inputValue          = { inputs.current_password || '' }
                                inputType           = { "password" }
                                formControlClasses  = { "light-input-container" } 
                                onChangeInput       = { e => this.setState({ inputs: { ...inputs, current_password: e.target.value } }) }
                            />
                            <PrimaryInput
                                inputLabel          = { "New Password" }
                                inputValue          = { inputs.new_password || '' }
                                inputType           = { "password" }
                                formControlClasses  = { "light-input-container" } 
                                onChangeInput       = { e => this.setState({ inputs: { ...inputs, new_password: e.target.value } }) }
                            />
                    </Collapse> 
                        
                    <Collapse 
                        in = { !this.state.showPassArea } >
                            <PrimaryInput
                                inputDisabled       = { true }
                                inputLabel          = { "Email" }
                                inputValue          = { authReducer.user.email }
                                inputType           = { "text" }
                                formControlClasses  = { "light-input-container" } 
                                onChangeInput       = { e => this.setState({ inputs: { ...inputs, current_password: e.target.value } }) }
                            />
                            <PrimaryInput
                                inputDisabled       = { true }
                                inputLabel          = { "Role" }
                                inputValue          = { Utility.UserRoleToStr(authReducer.user.role) }
                                inputType           = { "text" }
                                formControlClasses  = { "light-input-container" } 
                                onChangeInput       = { e => this.setState({ inputs: { ...inputs, new_password: e.target.value } }) }
                            />
                    </Collapse>

                    <div className="right-btn-flex">
                        <PrimaryButton 
                            buttonVariant  = "contained"
                            buttonText     = "Update"
                            inProgress     = { authReducer.profileUpdating }
                            buttonDisabled = { authReducer.profileUpdating }
                            onClick        = { this.onUpdateProfile }
                        />
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state){
    return {
        authReducer: state.authReducer,
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:    (msg)               =>  dispatch(setSnackMsg(msg)),
        setLoginUser:   (user)              => dispatch(setLoginUser(user)),
        setUserImg:     (imageUrl)          => dispatch(setUserImg(imageUrl)),
        updateProfile:  (url, body, cb)     => dispatch(updateProfile(url, body, cb)),
        uploadUserImg:  (url, formData, cb) => dispatch(uploadUserImg(url, formData, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);