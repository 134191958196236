import React from 'react';
import PropTypes from 'prop-types';
import '../styles.css';

const CountBadge = (props) => {
    return(
        <div className="count-badge-container">
            <div className="count-badge-icon-container" style={{backgroundColor: props.iconBgColor}}>
                { props.icon }
            </div>
            <div className="count-badge-counter-container" style={{backgroundColor: props.headingBgColor}}>
                <h4 style={{margin: 0}}>{ props.headingText }</h4>
                <h2 style={{margin: 0, padding: 0}}>{ props.counts }</h2>
            </div>
        </div>
    )
}

CountBadge.defaultProps = {
    headingText: 'No Text',
    counts:     0,
    iconBgColor: '#333',
    headingBgColor: '#555',
}

CountBadge.propTypes = {
    icon: PropTypes.node,
    headingText: PropTypes.string,
    counts:    PropTypes.number
}

export default CountBadge;