import React from 'react';
import { IconButton, Hidden } from '@mui/material';
import { MdMenu } from 'react-icons/md';
import PropTypes from 'prop-types';

import '../styles.css';

const MenuButton = (props) => {
    return (
        <Hidden smUp>
            <IconButton onClick = { props.onClickMenuBtn } >
                <MdMenu color="#fff" />
            </IconButton>
        </Hidden>
    )
}

const TitleText = () => {
    return (
        <div className="title-container">
            <h1 className='title-heading'>Bazi Console</h1>
        </div>
    )
} 

const Title = (props) => {
    return (
        <>
            <MenuButton { ...props } />
            <TitleText />
        </>
    )
}

Title.propsTypes = {
    onClickMenuBtn: PropTypes.func,
}

export default Title;