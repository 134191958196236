import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Paper } from '@mui/material';

import { ConfirmDialog } from '../../../components/dialog';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { Upload, /* ImagesList, IconsList */ } from './containers';

import './styles.css';
import Utility from '../../../lib/utility';
import { endPoints, reqMethods, perPage } from '../../../lib/values';
import { setTab, refreshImagesList, refreshIconsList, deleteFile, clearMediaManager, moveFile, setSnackMsg } from '../../../redux/media/media-actions';

class ManageMedia extends Component {

    state = {
        showConfirmDialog: false,
        snackErr: null
    }

    componentWillUnmount(){
        this.props.clearMediaManager();
    }

    onDeleteFile = () => {
        const { selectedFile } = this.props.mediaReducer;
        this.setState({showConfirmDialog: false});
        this.props.deleteFile(endPoints.media.deleteFile, Utility.httpRequest(reqMethods.delete, {_id: selectedFile._id}), (err, success) => {
            // do something after delete file
            if(err) this.setState({snackErr: err});
        })
    }

    onMoveFile = () => {
        const moveTo = this.props.mediaReducer.selectedTab === 2 ? 'icons' : 'images';
        this.props.moveFile(endPoints.media.moveFile, Utility.httpRequest(reqMethods.post, {_id: this.props.mediaReducer.selectedFile._id, moveTo}), (err, success) => {
            // do something after moving
        })
    }

    onReloadMedia = () => {
        this.setState({ pageNum: 1 }, () => {
            if(this.props.mediaReducer.selectedTab === 2){
                const url = `${endPoints.media.fetchImages}&limit=${perPage.mmFiles}&page=${this.state.pageNum}`;
                this.props.refreshImagesList(url, Utility.httpRequest(reqMethods.get), () => {
                    // do something on refresh filelist
                })
            } else if (this.props.mediaReducer.selectedTab === 3) {
                const url = `${endPoints.media.fetchIcons}&limit=${perPage.mmFiles}&page=${this.state.pageNum}`;
                this.props.refreshIconsList(url, Utility.httpRequest(reqMethods.get), () => {
                    // do something on refresh filelist
                })
            }
        })
        
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////
    //  render methods
    /////////////////////////////////////////////////////////////////////////////////////////////////

    renderHeader = () => {
        const { mediaReducer } = this.props;
        return (
            <Header 
                selectedTab         = { mediaReducer.selectedTab }
                onChangeTab         = {(e, value) => this.props.setTab(value) }
                onRefreshBtnClick   = { () => this.onReloadMedia() }
                hideCloseBtn        = { this.props.showAsSegment }
                onCloseMM           = { this.props.onClose }
                refreshBtnIndicator = { (mediaReducer.imagesRefreshing || mediaReducer.iconsRefreshing) ? true : false }
            />
        )
    }

    renderBody = () => {
        return (
            <div className="file-uploader-body">
                { this.renderBodyView() }
            </div>
        )
    }

    renderBodyView = () => {
        switch(this.props.mediaReducer.selectedTab){
            case 1:  return <Upload />
            // case 2:  return <ImagesList />
            // case 3:  return <IconsList />
            default: return <Upload />
        }
    }

    renderFooter = () => {
        const { selectedFile, selectedTab } = this.props.mediaReducer;
        return (
            <Footer 
                uploadTime          = { selectedFile ? selectedFile.uploadTime : ''}       
                uploadByUserName    = { (selectedFile && selectedFile.uploadBy) ? selectedFile.uploadBy.fullname : '' }   
                deleteIndicator     = { this.props.mediaReducer.fileDeleting } 
                onMoveClick         = { this.onMoveFile }
                moveIndicator       = { this.props.mediaReducer.movingFile }  
                onDeleteClick       = { () => this.setState({ showConfirmDialog: true })  }      
                viewableFileUrl     = { selectedFile ? selectedFile.publicUrl : '' }    
                disabledInsertBtn   = { (this.props.mediaReducer.fileDeleting || !this.props.onInsertFile) ? true : false }  
                onClickInsertBtn    = { () => this.props.onInsertFile(selectedFile) }
                isFileSelected      = { selectedFile ? true : false } 
                hideInsertButton    = { this.props.showAsSegment }
                selectedTab         = { selectedTab }
                snackMsg            = { this.props.mediaReducer.snackMsg }
                onCloseSnackMsg     = { () => this.props.setSnackMsg(null) }
            />
        )
    }

    renderConfirmDialog = () => {
        return (
            <ConfirmDialog 
                show      = { this.state.showConfirmDialog }
                title     = { "Delete Files" }
                message   = { "Are you sure you want to delete selected file?" }
                onCancel  = { () => this.setState({ showConfirmDialog: false }) }
                onConfirm = { this.onDeleteFile }
            />
        )
    }

    render(){
        return(
            <Paper className="file-uploader-wrapper">
                { this.renderConfirmDialog() }
                { this.renderHeader() } 
                { this.renderBody() }
                { this.renderFooter() }
            </Paper>
        )
    }
}

function mapStatetoProps(state){
    return {
        mediaReducer: state.mediaReducer
    }
}

function mapDispatchToProps(dispatch){
    return {
        setSnackMsg:         (msg)           => dispatch(setSnackMsg(msg)),
        setTab:              (tabValue)      => dispatch(setTab(tabValue)),
        refreshImagesList:   (url, body, cb) => dispatch(refreshImagesList(url, body, cb)),
        refreshIconsList:    (url, body, cb) => dispatch(refreshIconsList(url, body, cb)),
        deleteFile:          (url, body, cb) => dispatch(deleteFile(url, body, cb)),
        moveFile:            (url, body, cb) => dispatch(moveFile(url, body, cb)),
        clearMediaManager:   ()              => dispatch(clearMediaManager())
    }
}

export default connect(mapStatetoProps, mapDispatchToProps)(ManageMedia);