import React from 'react';
import { Drawer, Hidden, Toolbar, List } from '@mui/material';
import { connect } from 'react-redux';
import { MdDashboard,  MdPermMedia, MdViewAgenda, MdOutlineSupervisorAccount, MdStars, MdTimelapse, MdAssignment } from 'react-icons/md';

import { PrimaryListItem } from '../../../components/list';
import { setContentView } from '../../../redux/dom/dom-actions';
import menuData from './sidebardata';

const drawerWidth = 240;


function renderIcon(slug){
    switch(slug){
        case 'dashboard':
            return <MdDashboard size="24" />
        case 'news':
            return <MdAssignment size="24"  />
        case 'ranking':
            return <MdStars size="24" /> 
        case 'schedules':
            return <MdTimelapse size="24" />
        case 'category':
            return <MdViewAgenda size="24" />
        case 'media':
            return <MdPermMedia size="24" />
        case 'employees':
            return <MdOutlineSupervisorAccount size="24" />
        default:
            return <MdDashboard /> 
    }
}

const PrimaryDrawer = (props) => {
    return (
        <Drawer
            open    = { props.open }
            variant = { props.variant }
            onClose = { props.onCloseDrawer }
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
              }}>
                <Toolbar />
                <List>
                    { menuData.map((menuItem, i) =>
                        <PrimaryListItem 
                            key             = { i }
                            primaryText     = { menuItem.title } 
                            icon            = { renderIcon(menuItem.slug) }
                            iconStyles      = {{minWidth: 40, color: menuItem.slug === props.selectedView  && '#aa1d43' }}
                            prTextProps     = {{ style: { color: menuItem.slug === props.selectedView  ? '#aa1d43' : '#666' } }}
                            onClick         = { () => props.setContentView(menuItem.slug)  }
                            
                        />
                    )}
                </List>
        </Drawer>
    )
}

class Sidebar extends React.Component {
    
    render(){
        return (
            <>
                { /** Drawer will show on larger device ( > 600px) */ }
                <Hidden xsDown implementation="css">
                    <PrimaryDrawer 
                        { ...this.props } 
                        variant = { 'permanent' }
                        selectedView = { this.props.domReducer.contentView }
                    />
                </Hidden>
                { /** Drawer will show on smaller device ( <= 600px) */  }
                <Hidden smUp implementation="css"> 
                    <PrimaryDrawer 
                        { ...this.props } 
                        variant = { 'temporary' }
                        selectedView = { this.props.domReducer.contentView }
                    />
                </Hidden>
        </>
        )
    }
}

function mapStateToProps(state){
    return {
        domReducer: state.dom
    }
}

function mapDispatchToProps(dispatch){
    return {
        setContentView: (view) => dispatch(setContentView(view))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);